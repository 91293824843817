import _ from "lodash";
import type { AnyAction, Reducer } from "redux";

import { SUCCESS_SUFFIX } from "@/common/constants";
import { loadCriteriaFromLocalStorage } from "@/common/state/criteria/criteriaState";
import { debug } from "@/utils/common";

import { ProductActionTypes } from "./productActions";
import type { ProductState } from "./productState";
import productListInitialState from "./productState";

const productReducer: Reducer<ProductState> = (
	state: ProductState = productListInitialState,
	action: AnyAction
) => {
	switch (action.type) {
		case `${ProductActionTypes.LIST_PRODUCTS}${SUCCESS_SUFFIX}`:
			const { products, count } = action?.payload?.data;
			debug(products, count);

			const listProductsMapFunction = (item: any) => {
				const photo_urls = item?.photo_urls?.split("|") || [];
				_.remove(photo_urls, (url: string) => {
					return (
						_.startsWith(
							url,
							"https://static.ed.edmunds-media.com/unversioned/img/e17/no-vehicle"
						) || !_.startsWith(url, "http")
					);
				});
				item.photo_urls = photo_urls.join("|");

				return {
					details: item,
					featureType: item?.vehicle_type,
					features: item?.listing_feature,
					id: item?._id,
					image: item?.photo_urls
						? item?.photo_urls.split("|")[0]
						: "",
					interestRate: 0,
					make: item?.vehicle_make,
					mileage: item?.listing_mileage,
					model: item?.vehicle_model,
					monthly: "",
					price: item?.listing_price,
					stockNumber: item?.listing_stock,
					term: 60,
					thumbs: item?.photo_urls ? item?.photo_urls.split("|") : [],
					trim: item?.vehicle_trim,
					vin: item?.vin,
					year: item?.vehicle_year
				};
			};

			return {
				...state,
				// count: products?.length || 0,
				count: count ? count : 0,
				products:
					products && products.length > 0
						? products.map(listProductsMapFunction)
						: []
			};
		case `${ProductActionTypes.GET_PRODUCT}${SUCCESS_SUFFIX}`:
			const item = action?.payload?.data;

			const photo_urls = item?.photo_urls?.split("|") || [];
			_.remove(photo_urls, (url: string) => {
				return _.startsWith(
					url,
					"https://static.ed.edmunds-media.com/unversioned/img/e17/no-vehicle"
				);
			});
			item.photo_urls = photo_urls.join("|");

			return {
				...state,
				product: {
					details: item,
					featureType: item?.vehicle_type,
					features: item?.listing_feature,
					id: item?._id,
					image: item?.photo_urls
						? item?.photo_urls.split("|")[0]
						: "",
					interestRate: 0,
					make: item?.vehicle_make,
					mileage: item?.listing_mileage,
					model: item?.vehicle_model,
					monthly: "",
					price: item?.listing_price,
					stockNumber: item?.listing_stock,
					term: 0,
					thumbs: item?.photo_urls ? item?.photo_urls.split("|") : [],
					trim: item?.vehicle_trim,
					vin: item?.vin,
					year: item?.vehicle_year
				}
			};
		case `${ProductActionTypes.CLEAR_PRODUCT}`:
			return {
				...state,
				product: undefined
			};
		case `${ProductActionTypes.LIST_MAKES_MODELS}${SUCCESS_SUFFIX}`:
			debug(action);
			debug(action.payload);

			sessionStorage.setItem(
				"makesModels",
				JSON.stringify(action?.payload?.data || [])
			);

			const makes1 = _.uniq(_.map(action?.payload?.data, "make"));

			const criteria = loadCriteriaFromLocalStorage();

			if (
				action?.payload &&
				criteria?.criteria?.vehicle_makes?.length >= 0
			) {
				action.payload.data = _.filter(
					action?.payload?.data,
					(item) => {
						return _.includes(
							criteria?.criteria?.vehicle_makes,
							item.make
						);
					}
				);
			}

			const models1 = _.uniq(_.map(action?.payload?.data, "model"));

			const listMakesMapFunction1 = (item: string, index: number) => ({
				id: index,
				label: item,
				type: "make" // for react-autosuggest
			});
			const listModelsMapFunction1 = (item: string, index: number) => ({
				id: index,
				label: item,
				type: "model" // for react-autosuggest
			});
			return {
				...state,
				makes:
					makes1 && makes1.length > 0
						? makes1.map(listMakesMapFunction1)
						: [],
				models:
					models1 && models1.length > 0
						? models1.map(listModelsMapFunction1)
						: [],
				trims: []
			};

		case `${ProductActionTypes.LIST_TRIMS}${SUCCESS_SUFFIX}`:
			const trims = action?.payload?.data;
			const listTrimsMapFunction = (item: any, index: number) => ({
				id: index,
				label: item._id,
				type: "trim" // for react-autosuggest
			});
			return {
				...state,
				trims:
					trims && trims.length > 0
						? trims.map(listTrimsMapFunction)
						: []
			};
		case `${ProductActionTypes.SET_RANGE_MILEAGE}${SUCCESS_SUFFIX}`:
			const { min_listing_mileage, max_listing_mileage } =
				action?.payload?.data;
			return {
				...state,
				range: {
					...state.range,
					mileage: [
						Number(min_listing_mileage),
						Number(max_listing_mileage)
					]
				}
			};
		// case `${ProductActionTypes.SET_RANGE_PRICE}${SUCCESS_SUFFIX}`:
		// 	const { min, max } = action?.payload?.data;
		// 	return {
		// 		...state,
		// 		range: {
		// 			...state.range,
		// 			price: [Number(min), Number(max)]
		// 		}
		// 	};

		default:
			return state;
	}
};

export default productReducer;
