export const STARTED_SUFFIX = "_STARTED";
export const FAILED_SUFFIX = "_FAILED";
export const SUCCESS_SUFFIX = "_SUCCESS";

// Vehicle search query codes
// TODO: Update all instances of these strings in the codebase
// TODO: Put into object or different file for individual import
export const VEHICLE_MAKES_KEY = "vehicle_makes";
export const VEHICLE_MODELS_KEY = "vehicle_models";
export const VEHICLE_TRIMS_KEY = "vehicle_trims";
export const VEHICLE_ENGINE_CYLINDERS_KEY = "vehicle_engine_cylinders";
export const VEHICLE_FUEL_TYPES_KEY = "vehicle_fuel_types";
export const VEHICLE_DRIVETRAINS_KEY = "vehicle_drivetrains";
export const VEHICLE_TRANSMISSION_TYPES_KEY = "vehicle_transmission_types";
export const VEHICLE_YEAR_KEY = "vehicle_year";
export const LISTING_MILEAGE_KEY = "listing_mileage";
export const LISTING_PRICE_KEY = "listing_price";
export const ERRORS = {
	AMOUNT_TOO_SMALL: "amount_too_small",
	NON_FINANCIBLE: "non_financible"
};
