import _ from "lodash";
// import debounce from "lodash/debounce";
import React, { useState, Suspense } from "react";
// import Autosuggest from "react-autosuggest";
// import { CiSearch, CiCircleCheck } from "react-icons/ci";
import { connect } from "react-redux";
import type { Dispatch, AnyAction } from "redux";

import type { RootState } from "@/common/models";
import type { CriteriaActionProps } from "@/common/state/criteria/criteriaActions";
import {
	SetCriteriaAction,
	SetCriteriaListAction
} from "@/common/state/criteria/criteriaActions";
import type { TCriteria } from "@/common/state/criteria/criteriaState";
import type { ProductState } from "@/common/state/product/productState";
import { debug } from "@/utils/common";

import { PlusIcon } from "../Elements";

import PriceRange from "./PriceRange";

// const maxStringLength = 10

interface SearchComponentProps {
	product: ProductState;
	criteria: TCriteria;
	setCriteria: (criteria: CriteriaActionProps) => void;
	setCriteriaList: (list: CriteriaActionProps[]) => void;
}
// interface AutoSuggestion {
// 	id: number;
// 	label: string;
// 	type: string;
// }

const Search = (props: SearchComponentProps) => {
	const { criteria } = props;
	const { makes, models, trims } = props.product;

	// const [isShowSearch, setIsShowSearch] = useState(true);
	const [isShowMake, setIsShowMake] = useState(true);
	const [isShowModel, setIsShowModel] = useState(false);
	const [isShowTrim, setIsShowTrim] = useState(false);

	// const [searchQuery, setSearchQuery] = useState("");

	const [isShowMakeAll, setIsShowMakeAll] = useState(false);

	const [shouldLoadModel] = useState(false);

	// const [searchResults, setSearchResults] = useState<any[]>([]);

	// const handleInputChange = debounce((query) => {
	// 	setSearchQuery(query);

	// 	// Replace with search logic

	// 	const suggestedMakes =
	// 		makes?.filter(
	// 			(item: any) =>
	// 				item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
	// 		) || [];
	// 	const suggestedModels =
	// 		models?.filter(
	// 			(item: any) =>
	// 				item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
	// 		) || [];
	// 	// const suggestedTrims =
	// 	// 	trims?.filter(
	// 	// 		(item: any) =>
	// 	// 			item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
	// 	// 	) || [];

	// 	const searchResults = [];
	// 	if (suggestedMakes.length > 0) {
	// 		searchResults.push({
	// 			section: "Makes",
	// 			suggestions: suggestedMakes
	// 		});
	// 	}
	// 	if (suggestedModels.length > 0) {
	// 		searchResults.push({
	// 			section: "Models",
	// 			suggestions: suggestedModels
	// 		});
	// 	}
	// 	// if (suggestedTrims.length > 0) {
	// 	// 	searchResults.push({
	// 	// 		section: "Trims",
	// 	// 		suggestions: suggestedTrims
	// 	// 	});
	// 	// }
	// 	setSearchResults(searchResults);
	// }, 10);

	const handleChangeMake = (label: string) => {
		// setShouldLoadModel(true)
		const index = criteria.vehicle_makes?.indexOf(label);
		debug("index of label", index);
		let value: string[] = [];
		if (index !== undefined && index > -1) {
			if (criteria.vehicle_makes && criteria.vehicle_makes.length > 0) {
				const makes = criteria?.vehicle_makes.slice();
				value = [
					...makes.slice(0, index),
					...makes.slice(index + 1, makes.length)
				];
			}
		} else {
			value =
				criteria.vehicle_makes && criteria.vehicle_makes.length > 0
					? [...criteria.vehicle_makes, label]
					: [label];
		}

		const makesModels = JSON.parse(
			sessionStorage.getItem("makesModels") || "[]"
		);
		const allowedModels = _.uniq(
			_.map(
				_.filter(makesModels, (o) => {
					return !_.includes(value, o.make);
				}),
				"model"
			)
		);
		const newModels = _.filter(criteria["vehicle_models"], (o) => {
			return !_.includes(allowedModels, o);
		});

		props.setCriteriaList([
			{
				key: "vehicle_makes",
				value: value || []
			},
			{
				key: "vehicle_models",
				value: newModels
			},
			{
				key: "vehicle_trims",
				value: []
			}
		]);
	};

	const handleChangeModel = (label: string) => {
		const index = criteria.vehicle_models?.indexOf(label);

		// return
		let value;
		if (index !== undefined && index > -1) {
			const models = criteria.vehicle_models || [];
			value = [
				...models.slice(0, index),
				...models.slice(index + 1, models.length)
			];
		} else {
			value = criteria.vehicle_models
				? [...criteria.vehicle_models, label]
				: [label];
		}
		props.setCriteriaList([
			{
				key: "vehicle_models",
				value
			},
			{
				key: "vehicle_trims",
				value: []
			}
		]);
	};

	const handleChangeTrim = (label: string) => {
		const index = criteria.vehicle_trims?.indexOf(label);

		let value;
		if (index !== undefined && index > -1) {
			const trims = criteria?.vehicle_trims?.slice() || [];
			value = [
				...trims.slice(0, index),
				...trims.slice(index + 1, trims.length)
			];
		} else {
			value = criteria.vehicle_trims
				? [...criteria.vehicle_trims, label]
				: [label];
		}
		props.setCriteria({
			key: "vehicle_trims",
			value
		});
	};

	// const handleSuggestionSelected = (event: any, { suggestion }) => {
	// 	debug(suggestion);
	// 	switch (suggestion?.type) {
	// 		case "make":
	// 			handleChangeMake(suggestion?.label);
	// 			break;
	// 		case "model":
	// 			handleChangeModel(suggestion?.label);
	// 			break;
	// 		case "trim":
	// 			handleChangeTrim(suggestion?.label);
	// 			break;
	// 	}
	// 	setSearchResults([]);
	// 	setSearchQuery("");
	// };

	// const suggestionIsSelected = (suggestion: AutoSuggestion) => {
	// 	switch (suggestion?.type) {
	// 		case "make":
	// 			return criteria?.vehicle_makes?.indexOf(suggestion.label) >= 0;
	// 		case "model":
	// 			return criteria?.vehicle_models?.indexOf(suggestion.label) >= 0;
	// 		case "trim":
	// 			return criteria?.vehicle_trims?.indexOf(suggestion.label) >= 0;
	// 	}
	// 	return false;
	// };

	// const inputProps = {
	// 	// usually comes from the application state
	// 	onChange: (event: any, { newValue }) => {
	// 		setSearchQuery(newValue);
	// 	},
	// 	// called every time the input value changes
	// 	placeholder: "Search...",
	// 	value: searchQuery
	// };

	const Model = React.lazy(
		() =>
			new Promise<{ default: React.ComponentType }>((resolve) => {
				setTimeout(
					() => {
						const Component: React.FC = () =>
							models?.map((model, index) => (
								<div key={index} className="mb-5">
									<label className="flex items-center">
										<input
											type="checkbox"
											className="h-4 w-4 border-[#B8B8B8] mr-4"
											checked={
												criteria.vehicle_models &&
												criteria.vehicle_models.indexOf(
													model.label
												) > -1
											}
											onChange={() =>
												handleChangeModel(model.label)
											}
										/>
										<span className="text-base font-medium">
											{model.label}
										</span>
									</label>
								</div>
							));
						resolve({ default: Component });
					},
					// Math.random() * 500 + 500,
					100
				);
			})
	);

	return (
		<>
			{/* <div className="mb-5">
				<div
					className="text-base text-accent font-medium relative cursor-pointer pb-3 border-b-2 border-[#C8D1FF] mb-6"
					onClick={() => setIsShowSearch((value) => !value)}
				>
					Vehicle Search{" "}
					<span
						className={`${
							isShowSearch ? "active" : ""
						} absolute right-0 top-[5px] text-[#64748B] dropdown-button`}
					>
						<PlusIcon />
					</span>
				</div>
					<div className={`${isShowSearch ? "" : "closed"} mb-5 dropdown-container`}>
						<h3 className="text-[12px] text-[#8A839F] text-center font-[450]  mb-4 leading-normal">
							Use the search field below to search for vehicles by
							Make and Model
						</h3>
						<div className="relative mb-8 text-[#644C70] ">
							<Autosuggest
								multiSection={true}
								suggestions={searchResults}
								onSuggestionsFetchRequested={({ value }) => {
									return handleInputChange(value);
								}}
								onSuggestionsClearRequested={() => {
									setSearchResults([]);
								}}
								getSuggestionValue={(suggestion) => {
									return suggestion.label;
								}}
								renderSuggestion={(suggestion) => (
									<div
										className="flex justify-between"
										title={suggestion.label}
									>
										<p className="inline-block">
											{suggestion.label}{" "}
										</p>
										<CiCircleCheck
											className={`${
												suggestionIsSelected(suggestion)
													? ""
													: "hidden"
											} inline-block w-6 h-6`}
										/>
									</div>
								)}
								renderSectionTitle={(section) => (
									<div>
										<p>{section.section}</p>
									</div>
								)}
								getSectionSuggestions={(section) => {
									return section.suggestions;
								}}
								onSuggestionSelected={handleSuggestionSelected}
								inputProps={inputProps}
							/>
							<span className="absolute right-2 top-[6px]">
								<CiSearch className="w-6 h-6" />
							</span>
						</div>
					</div>
			</div> */}
			<PriceRange />
			<div className="">
				<div
					className="text-base text-accent font-medium relative cursor-pointer pb-3 border-b-2 border-[#C8D1FF] mb-6"
					onClick={() => setIsShowMake((value) => !value)}
				>
					Make{" "}
					<span
						className={`${
							isShowMake ? "active" : ""
						} absolute right-0 top-[5px] text-[#64748B] dropdown-button`}
					>
						<PlusIcon />
					</span>
				</div>
				<div
					className={`${
						isShowMake ? "" : "closed"
					} mb-5 dropdown-container`}
				>
					<div className="grid grid-cols-2 gap-1 max-h-[500px] overflow-y-scroll overflow-x-hidden">
						{isShowMakeAll
							? makes?.map(
									(make, index) =>
										make.label && (
											<div key={index} className="mb-5">
												<label className="flex items-center">
													<input
														type="checkbox"
														className="h-4 w-4 border-[#B8B8B8] mr-4"
														checked={
															criteria.vehicle_makes &&
															criteria.vehicle_makes?.indexOf(
																make.label
															) > -1
														}
														onChange={() =>
															handleChangeMake(
																make.label
															)
														}
													/>
													<span className="text-base font-medium">
														{/* {_.truncate(make.label, {
                                length: maxStringLength,
                              })} */}
														{make.label}
													</span>
												</label>
											</div>
										)
							  )
							: makes?.slice(0, 10).map(
									(make, index) =>
										make.label && (
											<div key={index} className="mb-5">
												<label className="flex items-center">
													<input
														type="checkbox"
														className="h-4 w-4 border-[#B8B8B8] mr-4"
														checked={
															criteria.vehicle_makes &&
															criteria.vehicle_makes?.indexOf(
																make.label
															) > -1
														}
														onChange={() =>
															handleChangeMake(
																make.label
															)
														}
													/>
													<span className="text-base font-medium">
														{/* {_.truncate(make.label, {
                                length: maxStringLength,
                              })} */}
														{make.label}
													</span>
												</label>
											</div>
										)
							  )}
					</div>
					<button
						className="text-[#5E78FF]"
						onClick={() => setIsShowMakeAll((value) => !value)}
					>
						{isShowMakeAll ? "See fewer makes" : "See all makes"}
					</button>
				</div>
			</div>
			<div className="">
				<div
					className={`text-base ${
						criteria.vehicle_makes &&
						criteria.vehicle_makes.length > 0
							? "text-accent"
							: "text-[#D9D9D9]"
					} font-medium relative cursor-pointer pb-3 border-b-2 border-[#C8D1FF] mb-6`}
					onClick={() => setIsShowModel((value) => !value)}
				>
					Model{" "}
					<span
						className={`${
							isShowModel ? "active" : ""
						} absolute right-0 top-[5px] text-[#64748B] dropdown-button`}
					>
						<PlusIcon />
					</span>
				</div>
				<div
					className={`${
						isShowModel ? "" : "closed"
					} mb-5 dropdown-container`}
				>
					<div className="grid grid-cols-1 gap-1 max-h-[300px] overflow-y-scroll overflow-x-hidden">
						{criteria.vehicle_makes &&
						criteria.vehicle_makes.length > 0 ? (
							shouldLoadModel ? (
								<Suspense
									fallback={
										<p className="w-[70%] m-auto text-[#5E78FF] text-center font-medium text-base">
											Loading available models for your
											location ...
										</p>
									}
								>
									<Model />
								</Suspense>
							) : (
								models?.map(
									(model, index) =>
										model.label && (
											<div key={index} className="mb-5">
												<label className="flex items-center">
													<input
														type="checkbox"
														className="h-4 w-4 border-[#B8B8B8] mr-4"
														checked={
															criteria.vehicle_models &&
															criteria.vehicle_models?.indexOf(
																model.label
															) > -1
														}
														onChange={() =>
															handleChangeModel(
																model.label
															)
														}
													/>
													<span className="text-base font-medium">
														{model.label}
													</span>
												</label>
											</div>
										)
								)
							)
						) : (
							<p className="w-[200px] text-center mx-auto text-[#B8B8B8]">
								Please select at least one vehicle
								make/manufacturer from the above list to view
								available models
							</p>
						)}
					</div>
				</div>
			</div>
			<div className="">
				<div
					className={`text-base ${
						criteria.vehicle_models &&
						criteria.vehicle_models.length > 0
							? "text-accent"
							: "text-[#D9D9D9]"
					} font-medium relative cursor-pointer pb-3 border-b-2 border-[#C8D1FF] mb-6`}
					onClick={() => setIsShowTrim((value) => !value)}
				>
					Trim{" "}
					<span
						className={`${
							isShowTrim ? "active" : ""
						} absolute right-0 top-[5px] text-[#64748B] dropdown-button`}
					>
						<PlusIcon />
					</span>
				</div>
				<div
					className={`${
						isShowTrim ? "" : "closed"
					} mb-5 dropdown-container`}
				>
					<div className="grid grid-cols-1 gap-1 max-h-[300px] overflow-y-scroll overflow-x-hidden">
						{criteria.vehicle_models &&
						criteria.vehicle_models.length > 0 ? (
							trims?.map(
								(trim, index) =>
									trim.label && (
										<div key={index} className="mb-5">
											<label className="flex items-center">
												<input
													type="checkbox"
													className="h-4 w-4 border-[#B8B8B8] mr-4"
													checked={
														criteria.vehicle_trims &&
														criteria.vehicle_trims.indexOf(
															trim.label
														) > -1
													}
													onChange={() =>
														handleChangeTrim(
															trim.label
														)
													}
												/>
												<span className="text-base font-medium">
													{/* {_.truncate(trim.label, { length: maxStringLength })} */}
													{trim.label}
												</span>
											</label>
										</div>
									)
							)
						) : (
							<p className="w-[200px] text-center mx-auto text-[#B8B8B8]">
								Please select at least one model from the list
								above to view available trim levels
							</p>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	criteria: state.app.criteria.criteria,
	product: state.app.product
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
	setCriteria: (criteria: CriteriaActionProps) =>
		dispatch(SetCriteriaAction(criteria)),
	setCriteriaList: (list: CriteriaActionProps[]) =>
		dispatch(SetCriteriaListAction(list))
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
