import { useState } from "react";
import ReactSlider from "react-slider";
import { Tooltip } from "react-tooltip";

import { debug, formatMoney, formatNumber, parseNumber } from "@/utils/common";

import { ArrowLeftIcon, ArrowRightIcon } from "../Elements";
import _ from "lodash";

interface InputWithSingleValueProps {
	defaultInputValue: number;
	label: string;
	slider: boolean;
	showCurrencySymbol: boolean;
	sliderRange?: number[];
	gradTrack?: boolean;
	toolTip?: boolean;
	showRange?: boolean;
	setValue?: (val: number) => void;
	onChangeAction?: any;
	country?: string;
	showCurrencyCode?: boolean;
	showFormatted?: boolean;
	labelGap?: boolean;
	isError?: boolean;
}

const InputWithSingleValue = ({
	defaultInputValue,
	label,
	slider = false,
	showCurrencySymbol,
	sliderRange,
	gradTrack = false,
	toolTip = false,
	showRange = false,
	setValue = undefined,
	onChangeAction,
	country,
	showCurrencyCode = false,
	showFormatted,
	labelGap = false,
	isError = false
}: InputWithSingleValueProps) => {
	const [inputValue, setInputValue] = useState<number>(defaultInputValue);
	const [formattedInputValue, setFormattedInputValue] = useState(
		formatNumber(defaultInputValue)
	);
	const setFormattedValue = (value: number) => {
		setFormattedInputValue(formatNumber(value));
	};
	return (
		<div>
			<p className="mb-2 text-[12px] font-[500] leading-[120%] tracking-[0.4px]">
				{label}
			</p>
			<div className="">
				{slider && (
					<div className={`mb-2 ${labelGap && "pt-[25px]"}`}>
						<ReactSlider
							value={inputValue}
							min={sliderRange![0]}
							max={sliderRange![1]}
							onChange={(value: any) => {
								debug("value: ", value);
								setInputValue(value);
								setValue?.(value);
								if (onChangeAction) onChangeAction();
							}}
							className="slider-cash-down"
							thumbClassName="slider-cash-down-thumb"
							trackClassName={
								gradTrack
									? "slider-credit-score-track"
									: "slider-cash-down-track"
							}
							// @ts-ignore
							renderThumb={(props: any) => {
								return (
									<div
										{...props}
										className={`${props.className} flex justify-center items-center`}
										data-tooltip-id="credit-score"
										data-tooltip-content={`${
											showCurrencySymbol
												? formatMoney(inputValue)
												: inputValue
										}`}
									>
										<span className="flex items-center relative h-full w-full">
											<ArrowLeftIcon />
											<ArrowRightIcon />
										</span>
										{toolTip && (
											<Tooltip
												id="credit-score"
												variant="light"
												className="border drop-shadow-sm"
											/>
										)}
									</div>
								);
							}}
						/>
						{showRange && (
							<div className="flex justify-between items-center">
								<span className="text-[#0F172A] text-[16px] md:text-sm">
									{showCurrencySymbol
										? formatMoney(sliderRange![0])
										: sliderRange![0]}
								</span>
								<span className="text-[#0F172A] text-[16px] md:text-sm">
									{showCurrencySymbol
										? formatMoney(sliderRange![1])
										: sliderRange![1]}
								</span>
							</div>
						)}
					</div>
				)}
				<div className="relative">
					<input
						className={`text-[16px] md:text-sm font-medium border w-full rounded-lg px-7 py-2 
                        ${
							isError
								? " border-[#FF0000] bg-[#f9aeae]"
								: " border-[#D9D9D9]"
						}`}
						value={showFormatted ? formattedInputValue : inputValue}
						onChange={(e) => {
							let num = parseNumber(e.target.value);
							if (_.isNaN(num)) {
								num = sliderRange![0];
							}
							if (showFormatted) {
								setFormattedValue(Number(num));
							} else {
								setInputValue(Number(num));
							}
							setValue?.(Number(num));
							if (onChangeAction) onChangeAction();
						}}
					/>
					<span className="absolute top-[9px] md:top-[6px] left-4">
						{showCurrencySymbol ? "$" : ""}
					</span>
					{showCurrencyCode && (
						<span className="absolute top-[13px] md:top-[10px] right-[15px] text-[12px] text-[#979797]">
							{country === "CA" ? "CAD" : "USD"}
						</span>
					)}
				</div>
			</div>
		</div>
	);
};

export default InputWithSingleValue;
