import type { ReactNode } from "react";

import Footer from "./Footer";
import Header from "./Header";

interface DefaultLayoutProps {
	children: ReactNode;
	logo: boolean;
	backgroundColor?: string;
}

const DefaultLayout = (props: DefaultLayoutProps) => {
	return (
		<div id="wrapper" className="bg-[#F0F5FA] min-h-screen flex flex-col">
			<Header logo={props.logo} />
			<div className="main-content flex-1">
				<main className="lg:flex pt-[32px] pb-[20px] md:py-[32px]">
					{props.children}
				</main>
			</div>
			<Footer />
		</div>
	);
};
export default DefaultLayout;
