/* eslint-disable sort-keys-fix/sort-keys-fix */
import { useEffect, useState } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useSelector, connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import type { AnyAction, Dispatch } from "redux";
import { bindActionCreators } from "redux";

import { VehicleContext } from "@/common/contexts";
import type { RootState } from "@/common/models";
// import ProductMap from '@/components/ProductMap'
import {
	ClearProductAction,
	GetProductAction
} from "@/common/state/product/productActions";
import Slider from "@/components/Slider";
import DefaultLayout from "@/layout/DefaultLayout";
import { formatMoney, formatNumber, debug, CONSTANTS } from "@/utils/common";

import EstimatedPayment from "./EstimatedPayment";
import type { BuildPaymentParam } from "./EstimatedPayment/BuildPayment";
import OverviewAndFeatures from "./OverviewAndFeatures";
import { Tooltip } from "react-tooltip";

interface VehicleProps {
	getProduct: (params: any) => void;
	clearProduct: () => void;
}
const Vehicles = (props: VehicleProps) => {
	// const params = new URLSearchParams(document.location.search)
	// const productId = params.get('id')
	const { country, id } = useParams();
	const product = useSelector(
		(state: RootState) => state.app.product.product
	);
	const location = useSelector((state: RootState) => state.app.location);

	const mileageUnit =
		useSelector(
			(state: RootState) => state.app.criteria.criteria.mileage_unit
		) || "N";
	let mileage = product?.mileage || 0;
	if (mileageUnit === "K") {
		mileage = Math.round(mileage * CONSTANTS.kmPerMile);
	}

	const [buildParam, setBuildParam] = useState<BuildPaymentParam>({
		Country: country,
		CreditScore: 650,
		Downpayment: 2000,
		Income: 36000,
		ListPrice: 0,
		LoanTermMax: 96,
		LoanTermMin: 12,
		MonthlyPayment: 1000,
		PostCode: location?.postal || "",
		TradeinValue: 1000,
		VehicleMake: "",
		VehicleModel: "",
		VehicleTrim: "",
		VehicleVin: ""
	});
	// const [coordinate, setCoordinate] = useState<ICoordinate>({
	//   latitude: 0,
	//   longitude: 0,
	// })
	useEffect(() => {
		if (!product || String(product.id) !== id) return;
		// generatePayment();
	}, [product]);

	useEffect(() => {
		props.clearProduct();
		debug("product id is", id);
		if (id) {
			props.getProduct({ country, id });
		}
	}, [country, id]);
	const vehicleNameFull = `${product?.year} ${product?.make} ${product?.model} ${product?.trim}`;

	return (
		<DefaultLayout logo={false}>
			<VehicleContext.Provider value={{ buildParam, setBuildParam }}>
				<div className="w-full relative p-0">
					{product ? (
						<>
							{/* Mobile View */}
							<div className="w-full pl-[10px] pr-[10px] pt-[0px] mt-[-12px] md:hidden">
								<div className="md:hidden flex flex-col gap-[12px]">
									<Link
										to={"/"}
										className="flex items-center text-accent gap-1"
									>
										<HiOutlineArrowNarrowLeft />{" "}
										<span className="font-[400] tracking-[0.025rem] text-[#0169FF] text-[16px]">
											Back to results
										</span>
									</Link>
									<div className="w-full flex font-[500] justify-between leading-8 tracking-[0.4px] text-[#4A495C] px-[21px]">
										<h2
											className="font-circular-std text-[#4A495C] flex text-[24px] float-left truncate"
											data-tooltip-id="vehicle-name-full-mobile"
											data-tooltip-content={
												vehicleNameFull
											}
										>
											{vehicleNameFull}
										</h2>
										<Tooltip
											id="vehicle-name-full-mobile"
											className="border drop-shadow-sm"
										/>
									</div>
									<div className="w-full">
										<Slider product={product!} />
									</div>
									<div className="w-full flex font-[500] justify-between leading-8 tracking-[0.4px] gap-[16px] px-[21px]">
										<div className="flex flex-col">
											<p className="flex text-[35px] text-[#89C664] whitespace-nowrap float-left ">
												{formatMoney(
													Number(product?.price || 0)
												)}
											</p>
											<span className="text-[16px] font-normal text-[#666678] mt-[16px]">
												Seller Price
											</span>
										</div>
										<div className="flex flex-col items-end	">
											<p className="flex text-[35px] text-[#666678] whitespace-nowrap float-right">
												{formatNumber(mileage)}{" "}
												{mileageUnit === "N"
													? `mi`
													: `km`}
											</p>
											<span className="text-[16px] font-normal text-[#666678] mt-[16px]">
												Mileage
											</span>
										</div>
									</div>
									<span className="flex items-center gap-[4px] px-[21px] mt-[12px]">
										<span className="text-[16px] font-[400] text-[#666678]">
											VIN:
										</span>
										<span className="text-[16px] font-[700] text-[#666678]">
											{product.vin}
										</span>
									</span>
									<span className="flex items-center gap-[4px] px-[21px] mt-[8px]">
										<span className="text-[16px] font-[400] text-[#666678]">
											Stock#:
										</span>
										<span className="text-[16px] font-[700] text-[#666678]">
											{product.details.listing_stock}
										</span>
									</span>
									<div className="px-[21px]">
										<EstimatedPayment product={product} />
									</div>
									<div className="px-[21px]">
										<div className="bg-white rounded-[8px] px-[24px] pt-[27px] pb-[60px]">
											<OverviewAndFeatures
												product={product}
											/>
										</div>
									</div>
								</div>
							</div>
							{/* Tablet and desktop view */}
							<div className="hidden md:block w-full pl-[32px] pr-[32px] py-[16px]">
								<div className="bg-white rounded-[8px] px-[24px] pt-[24px] pb-[36px] md:flex flex-col gap-[24px] hidden">
									<Link
										to={"/"}
										className="flex items-center text-accent gap-1"
									>
										<HiOutlineArrowNarrowLeft />{" "}
										<span className="font-[400] tracking-[0.025rem] text-[#0169FF]">
											Back to results
										</span>
									</Link>
									<div className="w-full flex font-[500] justify-between leading-[1.1] tracking-[0.4px] mb-[20px]">
										<h2
											className="font-circular-std text-[#4A495C] block text-[24px] md:text-[36px] float-left truncate"
											data-tooltip-id="vehicle-name-full"
											data-tooltip-content={
												vehicleNameFull
											}
										>
											{vehicleNameFull}
										</h2>
										<Tooltip
											id="vehicle-name-full"
											className="border drop-shadow-sm"
										/>
									</div>
									<div className="w-full flex font-[500] justify-between leading-8 tracking-[0.4px] mt-[-20px] gap-[20px]">
										<div className="flex flex-col">
											<p className="flex text-[60px] text-[#89C664] whitespace-nowrap float-left ">
												{formatMoney(
													Number(product?.price || 0)
												)}{" "}
												{country !==
												location?.country_code
													? `${
															country === "US"
																? "USD"
																: "CAD"
													  }`
													: null}
											</p>
											<span className="text-[16px] font-normal text-[#666678] mt-[16px]">
												Seller Price
											</span>
										</div>
										<div className="flex flex-col items-end	">
											<p className="flex text-[60px] text-[#666678] whitespace-nowrap float-right">
												{formatNumber(mileage)}{" "}
												{mileageUnit === "N"
													? `mi`
													: `km`}
											</p>
											<span className="text-[16px] font-normal text-[#666678] mt-[16px]">
												Mileage
											</span>
										</div>
									</div>
									<div className="w-full grid grid-cols-8 gap-[20px] mb-[20px]">
										<div className="col-span-5">
											<div className="flex justify-between">
												<span className="flex items-center gap-[4px]">
													<span className="text-[16px] font-[400] text-[#666678]">
														VIN:
													</span>
													<span className="text-[16px] font-[700] text-[#666678]">
														{product.vin}
													</span>
												</span>
												<span className="flex items-center gap-[4px]">
													<span className="text-[16px] font-[400] text-[#666678]">
														Stock#:
													</span>
													<span className="text-[16px] font-[700] text-[#666678]">
														{
															product.details
																.listing_stock
														}
													</span>
												</span>
												{/* Hidden until we define the prop
                                                <span className="flex items-center gap-[4px]">
													<span>Days on market:</span>
													<span className="text-[16px] font-normal">
														{product.vin}
													</span>
												</span> */}
											</div>
										</div>
									</div>

									<div className="w-full grid grid-cols-8 gap-[20px]">
										<div className="col-span-8 lg:col-span-5">
											<Slider product={product!} />
										</div>
										<div className="col-span-8 lg:col-span-3">
											<EstimatedPayment
												product={product}
											/>
										</div>
									</div>

									<div className="w-full grid grid-cols-8 gap-[20px]">
										<div className="col-span-8 lg:col-span-5">
											<OverviewAndFeatures
												product={product}
											/>
										</div>
									</div>
								</div>
							</div>
						</>
					) : (
						<h3 className="text-center m-6">Loading...</h3>
					)}
				</div>
			</VehicleContext.Provider>
		</DefaultLayout>
	);
};

export default connect(null, (dispatch: Dispatch<AnyAction>) => ({
	getProduct: bindActionCreators(GetProductAction, dispatch),
	clearProduct: bindActionCreators(ClearProductAction, dispatch)
}))(Vehicles);
