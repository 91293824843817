/* eslint-disable prettier/prettier */
import _ from "lodash";
import type { AnyAction, Reducer } from "redux";

import { isEmpty, debug } from "@/utils/common";

import type { CriteriaActionProps } from "./criteriaActions";
import { CriteriaActionTypes } from "./criteriaActions";
import type { CriteriaState } from "./criteriaState";
import criteriaInitialState, { criteriaEmptyState } from "./criteriaState";

const criteriaReducer: Reducer<CriteriaState> = (
	state = criteriaInitialState,
	action: AnyAction
) => {
	switch (action.type) {
		case `${CriteriaActionTypes.SET_CRITERIA}`:
			debug("criteria reducer, set criteria", action.payload);
			const { value, key, enable } = action.payload;

			let enableToSet = false;
			if (_.isBoolean(enable)) enableToSet = enable;
			else {
				enableToSet =
					key === "distance" ||
					key === "distance_unit" ||
					key === "mileage_unit" ||
					key === "sort"
						? false
						: !isEmpty(value);
			}

			return {
				...state,
				criteria: {
					...state.criteria,
					[key]: value
				},
				enable: {
					...state.enable,
					[key]: enableToSet
				}
			};
		case `${CriteriaActionTypes.SET_CRITERIA_LIST}`:
			const newCriteria: any = {};
			const newEnables: any = {};

			action.payload.forEach((item: CriteriaActionProps) => {
				let enableToSet = false;
				if (_.isBoolean(item.enable)) enableToSet = item.enable;
				else {
					enableToSet =
						item.key === "distance" ||
						item.key === "distance_unit" ||
						item.key === "mileage_unit" ||
						item.key === "sort"
							? false
							: !isEmpty(item.value);
				}

				newCriteria[item.key] = item.value;
				newEnables[item.key] = enableToSet;
			});

			return {
				...state,
				criteria: {
					...state.criteria,
					...newCriteria
				},
				enable: {
					...state.enable,
					...newEnables
				}
			};
		case `${CriteriaActionTypes.CLEAR_CRITERIA}`:
			return criteriaEmptyState;
		default:
			return state;
	}
};

export default criteriaReducer;
