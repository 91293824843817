import _ from "lodash";
import { useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Dispatch, AnyAction } from "redux";

import * as Constants from "@/common/constants";
import type { RootState } from "@/common/models";
import type {
	CriteriaActionProps,
	CriteriaKey
} from "@/common/state/criteria/criteriaActions";
import {
	SetCriteriaAction,
	SetCriteriaListAction
} from "@/common/state/criteria/criteriaActions";
import type { CriteriaState } from "@/common/state/criteria/criteriaState";
import { criteriaEmptyState } from "@/common/state/criteria/criteriaState";
import { formatMoney, formatNumber } from "@/utils/common";

import Chip from "./Chip";
export interface ChipBarProps {
	criteria: CriteriaState;
	setCriteria: (c: CriteriaActionProps) => void;
	setCriteriaList: (list: CriteriaActionProps[]) => void;
}
const arrayKey = [
	Constants.VEHICLE_MAKES_KEY,
	Constants.VEHICLE_MODELS_KEY,
	Constants.VEHICLE_TRIMS_KEY,
	Constants.VEHICLE_ENGINE_CYLINDERS_KEY,
	Constants.VEHICLE_FUEL_TYPES_KEY,
	Constants.VEHICLE_DRIVETRAINS_KEY,
	Constants.VEHICLE_TRANSMISSION_TYPES_KEY
];

const rangeKey = [
	Constants.LISTING_MILEAGE_KEY,
	Constants.LISTING_PRICE_KEY,
	Constants.VEHICLE_YEAR_KEY
];
const ChipBar = (props: ChipBarProps) => {
	const { criteria, enable } = props.criteria;
	const getMileageUnit = useCallback(
		() => (criteria.mileage_unit === "K" ? "km" : "mile"),
		[criteria]
	);
	const handleChangeFilters = (e: any, key: CriteriaKey, value = "") => {
		e.stopPropagation();
		if (arrayKey.includes(key)) {
			const index = criteria[key].indexOf(value);

			const newArray = [
				...criteria[key].slice(0, index),
				...criteria[key].slice(index + 1, criteria[key].length)
			];
			if (
				[
					Constants.VEHICLE_MAKES_KEY,
					Constants.VEHICLE_MODELS_KEY,
					Constants.VEHICLE_TRIMS_KEY
				].includes(key)
			) {
				switch (key) {
					case Constants.VEHICLE_MAKES_KEY:
						const makesModels = JSON.parse(
							sessionStorage.getItem("makesModels") || "[]"
						);
						const allowedModels = _.uniq(
							_.map(
								_.filter(makesModels, (o) => {
									return !_.includes(newArray, o.make);
								}),
								"model"
							)
						);
						const newModels = _.filter(
							criteria[Constants.VEHICLE_MODELS_KEY],
							(o) => {
								return !_.includes(allowedModels, o);
							}
						);

						props.setCriteriaList([
							{
								key: Constants.VEHICLE_MAKES_KEY,
								value: newArray
							},
							{
								key: Constants.VEHICLE_MODELS_KEY,
								value: newModels
							},
							{
								key: Constants.VEHICLE_TRIMS_KEY,
								value: []
							}
						]);
						break;
					case Constants.VEHICLE_MODELS_KEY:
						props.setCriteriaList([
							{
								key: Constants.VEHICLE_MODELS_KEY,
								value: newArray
							},
							{
								key: Constants.VEHICLE_TRIMS_KEY,
								value: []
							}
						]);
						break;
					case Constants.VEHICLE_TRIMS_KEY:
						props.setCriteria({
							key: Constants.VEHICLE_TRIMS_KEY,
							value: newArray
						});
				}
			} else {
				props.setCriteria({
					key,
					value: newArray
				});
			}
		} else if (rangeKey.includes(key)) {
			props.setCriteria({
				enable: false,
				key,
				value: criteriaEmptyState.criteria[key]
			});
		}
	};
	return (
		<>
			{enable?.vehicle_makes &&
				criteria.vehicle_makes.map((item: any, key: number) => (
					<Chip
						key={key}
						label={item}
						bgColor={"bg-primary"}
						onClick={(e) =>
							handleChangeFilters(
								e,
								Constants.VEHICLE_MAKES_KEY,
								item
							)
						}
					/>
				))}
			{enable?.vehicle_models &&
				criteria.vehicle_models.map((item: any, key: number) => (
					<Chip
						key={key}
						label={item}
						bgColor={"bg-accent"}
						onClick={(e) =>
							handleChangeFilters(
								e,
								Constants.VEHICLE_MODELS_KEY,
								item
							)
						}
					/>
				))}
			{enable?.vehicle_trims &&
				criteria.vehicle_trims.map((item: any, key: number) => (
					<Chip
						key={key}
						label={item}
						bgColor={"bg-accent"}
						onClick={(e) =>
							handleChangeFilters(
								e,
								Constants.VEHICLE_TRIMS_KEY,
								item
							)
						}
					/>
				))}
			{enable?.vehicle_year && (
				<Chip
					label={`${criteria.vehicle_year[0]} - ${criteria.vehicle_year[1]}`}
					bgColor={"bg-primary"}
					onClick={(e) =>
						handleChangeFilters(e, Constants.VEHICLE_YEAR_KEY)
					}
				/>
			)}
			{enable?.listing_price && (
				<Chip
					label={`${formatMoney(criteria.listing_price[0] || 0)}
            -
            ${formatMoney(criteria.listing_price[1] || 0)}`}
					bgColor="bg-[#89C664]"
					onClick={(e) =>
						handleChangeFilters(e, Constants.LISTING_PRICE_KEY)
					}
				/>
			)}
			{enable?.listing_mileage && (
				<Chip
					label={`${formatNumber(
						criteria.listing_mileage[0]
					)} ${getMileageUnit()}
            -
            ${formatNumber(criteria.listing_mileage[1])} ${getMileageUnit()}`}
					bgColor={"bg-primary"}
					onClick={(e) =>
						handleChangeFilters(e, Constants.LISTING_MILEAGE_KEY)
					}
				/>
			)}
			{enable?.vehicle_fuel_types &&
				criteria.vehicle_fuel_types.map((item: any, key: number) => (
					<Chip
						key={key}
						label={item}
						bgColor={"bg-primary"}
						onClick={(e) =>
							handleChangeFilters(
								e,
								Constants.VEHICLE_FUEL_TYPES_KEY,
								item
							)
						}
					/>
				))}
			{enable?.vehicle_engine_cylinders &&
				criteria.vehicle_engine_cylinders.map(
					(item: any, key: number) => (
						<Chip
							key={key}
							label={
								item === -1
									? "8+ Cylinders"
									: item + " Cylinders"
							}
							bgColor={"bg-primary"}
							onClick={(e) =>
								handleChangeFilters(
									e,
									Constants.VEHICLE_ENGINE_CYLINDERS_KEY,
									item
								)
							}
						/>
					)
				)}
			{enable?.vehicle_drivetrains &&
				criteria.vehicle_drivetrains.map((item: any, key: number) => (
					<Chip
						key={key}
						label={item}
						bgColor={"bg-primary"}
						onClick={(e) =>
							handleChangeFilters(
								e,
								Constants.VEHICLE_DRIVETRAINS_KEY,
								item
							)
						}
					/>
				))}
			{enable?.vehicle_transmission_types &&
				criteria.vehicle_transmission_types.map(
					(item: any, key: number) => (
						<Chip
							key={key}
							label={item}
							bgColor={"bg-primary"}
							onClick={(e) =>
								handleChangeFilters(
									e,
									Constants.VEHICLE_TRANSMISSION_TYPES_KEY,
									item
								)
							}
						/>
					)
				)}
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	criteria: state.app.criteria
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
	setCriteria: bindActionCreators(SetCriteriaAction, dispatch),
	setCriteriaList: bindActionCreators(SetCriteriaListAction, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ChipBar);
