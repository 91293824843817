import type { PropsWithChildren } from "react";
import "./toast.css";

interface ToastNotificationProps {
	title?: string;
	text?: string;
	onClose?: () => void;
}

export default function ToastNotification({
	title = "Stock number copied to clipboard.",
	// text = "Vehicle stock number has been successfully copied to your clipboard.",
	onClose
}: PropsWithChildren<ToastNotificationProps>) {
	return (
		<div className="toast">
			<button className="toast-close-btn" onClick={onClose}>
				<span className="icon">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
					>
						<path
							d="M2 2L14 14"
							stroke="#1D2D13"
							stroke-width="3"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M14 2L2 14"
							stroke="#1D2D13"
							stroke-width="3"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</span>
			</button>
			<h1 className="toast-title">{title}</h1>
			{/* <p className="toast-text">{text}</p> */}
		</div>
	);
}
