import type { PropsWithChildren } from "react";
import { useState } from "react";
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";

import { sendToUltralead } from "@/common/services/apiService";
import type { IProduct } from "@/common/state/product/productState";
import ModalExtensive from "@/components/Modal/ModalExtensive";
import { debug, isValidEmail, isValidPostal, isValidZip } from "@/utils/common";

import type { BuildPaymentParam } from "../EstimatedPayment/BuildPayment";

interface ContactDealerModalContentProps {
	onClose: () => void;
	product: IProduct;
	userBuildParam: BuildPaymentParam;
}

export default function ContactDealerModal({
	onClose,
	product,
	userBuildParam
}: PropsWithChildren<ContactDealerModalContentProps>) {
	const [email, setEmail] = useState("");
	const [recaptchaValue, setRecaptchaValue] = useState("");
	const [activeModal, setActiveModal] = useState("contactModal");
	const [isLoading, setIsLoading] = useState(false);

	// const emailInputRef = useRef<HTMLInputElement | null>(null);
	// const recaptchaRef = useRef<ReCAPTCHA | null>(null);

	const onEmailInputChange = (value: string) => {
		const trimmedValue = value.trim();
		setEmail(trimmedValue);
	};
	function onChangeRecaptcha(value: string | null) {
		debug("Captcha value:", value);
		setRecaptchaValue(value ? value : "");
	}
	function clearRecaptcha() {
		setRecaptchaValue("");
	}

	// useEffect(() => {
	// 	if (emailInputRef.current) {
	// 		emailInputRef.current.focus();
	// 	}
	// }, [email]);

	const onSendLead = async () => {
		try {
			if (
				recaptchaValue &&
				isValidEmail(email) &&
				((userBuildParam?.Country === "CA" &&
					isValidPostal(userBuildParam?.PostCode || "", true)) ||
					(userBuildParam?.Country === "US" &&
						isValidZip(userBuildParam?.PostCode || "")))
			) {
				setIsLoading(true);

				const result = await sendToUltralead({
					customer: {
						address: {
							country: userBuildParam?.Country || null,
							postal: userBuildParam?.PostCode || null
						},
						email: email,
						first: null,
						last: null,
						phone: null
					},
					finance: {
						amount: userBuildParam?.ListPrice?.toString() || null,
						credit: userBuildParam?.CreditScore?.toString() || null,
						down_payment:
							userBuildParam?.Downpayment?.toString() || null,
						income: userBuildParam?.Income?.toString() || null,
						max_monthly:
							userBuildParam?.MonthlyPayment?.toString() || null,
						method: "finance",
						term: userBuildParam?.LoanTermMax?.toString() || null,
						trade: userBuildParam?.TradeinValue?.toString() || null
					},
					"g-recaptcha-response": recaptchaValue,
					provider: {
						name: "AutoYaYa"
					},
					received: new Date().toISOString(),
					vehicle: {
						make: product?.make,
						model: product?.model,
						price: product?.price?.toString(),
						stock: product?.stockNumber,
						vin: product?.vin,
						year: product?.year?.toString()
					},
					vendor: {
						name: product?.details?.va_seller_name
					}
				});

				debug(result);
				setIsLoading(false);

				if (result?.data?.success) {
					setActiveModal("emailSentModal");
				} else {
					setActiveModal("errorModal");
				}
				return;
			} else {
				setActiveModal("invalidModal");
			}
		} catch (error) {
			debug(error);
			setActiveModal("errorModal");
		}
	};

	const handleModalExternalLink = (e: MouseEvent) => {
		// console.log("hello");
		if (e?.target?.href) {
			window.open(e?.target?.href, "_blank").focus();
		}
	};

	return (
		<ModalExtensive title="Contact Dealer" onClose={onClose}>
			<form
				className={`${
					activeModal === "contactModal" ? "" : "hidden"
				} flex flex-col items-center gap-10 mt-5 self-stretch`}
			>
				<div className="flex flex-col items-start gap-4 self-stretch ">
					<p className="font-circular-std font-medium text-accents-violet text-lg  text-[#3B2F66]">
						Want to let the dealer know you're interested in this
						vehicle?
					</p>
					<p className="font-circular-std font-medium text-accents-violet text-lg  text-[#3B2F66]">
						Enter your email below so the dealer can contact you. We
						will not share it with anyone else{" "}
						<span className="text-[#5E78FF]">*</span>.
					</p>
				</div>
				<div className="w-full md:w-4/5 m-auto">
					<p className="mb-1 font-circular-std text-[#0F172A] text-xs font-medium leading-4 tracking-wider">
						Your Email Address
					</p>
					<input
						type="email"
						placeholder="email@email.com"
						value={email}
						onChange={(e) => onEmailInputChange(e.target.value)}
						disabled={isLoading}
						className="flex w-full h-[44px] p-4 justify-end items-center gap-4 rounded-md border border-[#5E78FF] bg-white font-[450]"
					/>
				</div>

				<ReCAPTCHA
					sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
					onChange={onChangeRecaptcha}
					onErrored={clearRecaptcha}
					onExpired={clearRecaptcha}
					size="normal"
				/>
				<button
					onClick={onSendLead}
					className={`${
						!isLoading && isValidEmail(email)
							? "border border-[#4B76C2] text-[#FFF] bg-[#5E78FF]"
							: "border border-[#E2DEDD] text-[#BAABA7] bg-[#EFEBE9]"
					} flex py-[18px] px-[30px] justify-center items-center space-x-4 rounded-lg shadow-md text-xl`}
					disabled={isLoading || !isValidEmail(email)}
				>
					Send to Dealer
				</button>
			</form>

			<p className="text-center font-circular-std text-black text-xs font-[450] mt-10">
				<b className="font-[500]">* Pinkie Swear.</b> If you don’t
				believe us, our terms of service{" "}
				<a
					href="https://corp.carbeeza.com/dealer-terms-of-service"
					target="_blank"
					className="text-[#5E78FF]"
					onClick={handleModalExternalLink}
				>
					here
				</a>
				, and our privacy policy is{" "}
				<a
					href="https://corp.carbeeza.com/privacy-policy"
					target="_blank"
					className="text-[#5E78FF]"
					onClick={handleModalExternalLink}
				>
					here
				</a>
				.<br /> If after all of that <i>you still</i> have questions
				about how we don’t use your information{" "}
				<a
					href="https://corp.carbeeza.com/contactus"
					target="_blank"
					className="text-[#5E78FF]"
					onClick={handleModalExternalLink}
				>
					contact us
				</a>
				.
			</p>
			<div
				className={`${
					activeModal === "emailSentModal" ? "" : "hidden"
				} flex flex-col md:w-2/3 mx-auto gap-5 mt-8`}
			>
				<p className="text-center font-circular-std text-accents-violet text-[#3B2F66] text-base font-[450]">
					Message sent! Keep an eye on your inbox, the dealer will
					contact you within a few business days.
				</p>
				<p className="font-circular-std text-accents-violet text-base font-[600] text-[#3B2F66] text-center">
					Finally, thank you for using AutoYaYa!
				</p>
			</div>
			<div
				className={`${
					activeModal === "errorModal" ? "" : "hidden"
				} flex flex-col md:w-2/3 mx-auto gap-5 mt-8`}
			>
				<p className="text-center font-circular-std text-accents-violet text-[#3B2F66] text-base font-[450]">
					We've experienced an error. Please try again later.
				</p>
			</div>
			<div
				className={`${
					activeModal === "invalidModal" ? "" : "hidden"
				} flex flex-col md:w-2/3 mx-auto gap-5 mt-8`}
			>
				<p className="text-center font-circular-std text-accents-violet text-[#3B2F66] text-base font-[450]">
					We couldn't send your request. Check that your email and
					postal code are valid, and make sure you check off "I am not
					a robot."
				</p>
			</div>
		</ModalExtensive>
	);
}
