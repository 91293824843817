import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import type { RootState } from "@/common/models";
import { SetCriteriaAction } from "@/common/state/criteria/criteriaActions";

import { PlusIcon } from "../Elements";

const transmissionList = [
	{ label: "Automatic", value: 1 },
	{ label: "Manual", value: 2 }
];

const drivetrainList = [
	{ label: "All", value: 1 },
	{ label: "AWD", value: 2 },
	{ label: "FWD", value: 3 },
	{ label: "RWD", value: 4 }
];

const Drivetrain = () => {
	const [show, setShow] = useState(true);

	const criteria = useSelector(
		(state: RootState) => state.app.criteria.criteria
	);
	const dispatch = useDispatch();

	const handleSelectTransmission = (index: number) => {
		const type = transmissionList[index].label;
		const idx = (criteria.vehicle_transmission_types || [])?.indexOf(type);

		if (idx === -1)
			dispatch(
				SetCriteriaAction({
					key: "vehicle_transmission_types",
					value: criteria.vehicle_transmission_types
						? [...criteria.vehicle_transmission_types, type]
						: [type]
				})
			);
		else
			dispatch(
				SetCriteriaAction({
					key: "vehicle_transmission_types",
					value: criteria.vehicle_transmission_types
						? [
								...criteria.vehicle_transmission_types.slice(
									0,
									idx
								),
								...criteria.vehicle_transmission_types.slice(
									idx + 1,
									criteria.vehicle_transmission_types.length
								)
						  ]
						: []
				})
			);
	};

	const handleSelectDrivetrain = (value: number) => {
		if (value === 1) {
			if (
				criteria.vehicle_drivetrains &&
				criteria.vehicle_drivetrains.length ===
					drivetrainList.length - 1
			) {
				dispatch(
					SetCriteriaAction({
						key: "vehicle_drivetrains",
						value: []
					})
				);
			} else {
				dispatch(
					SetCriteriaAction({
						key: "vehicle_drivetrains",
						value: ["AWD", "FWD", "RWD"]
					})
				);
			}

			return;
		}

		const drivetrain = drivetrainList.filter((e) => e.value === value)[0]
			.label;
		const idx = (criteria.vehicle_drivetrains || [])?.indexOf(drivetrain);

		if (idx === -1)
			dispatch(
				SetCriteriaAction({
					key: "vehicle_drivetrains",
					value: criteria.vehicle_drivetrains
						? [...criteria.vehicle_drivetrains, drivetrain]
						: [drivetrain]
				})
			);
		else
			dispatch(
				SetCriteriaAction({
					key: "vehicle_drivetrains",
					value: criteria.vehicle_drivetrains
						? [
								...criteria.vehicle_drivetrains.slice(0, idx),
								...criteria.vehicle_drivetrains.slice(
									idx + 1,
									criteria.vehicle_drivetrains.length
								)
						  ]
						: []
				})
			);
	};
	return (
		<div>
			<div
				className="text-base text-accent font-medium relative cursor-pointer pb-3 border-b-2 border-[#C8D1FF]"
				onClick={() => setShow((value) => !value)}
			>
				Drivetrain{" "}
				<span
					className={`${
						show ? "active" : ""
					} absolute right-0 top-[5px] text-[#64748B] dropdown-button`}
				>
					<PlusIcon />
				</span>
			</div>
			<div className={`${show ? "" : "closed"} mb-5 dropdown-container`}>
				<div className="mt-5 text-[#0F172A] text-sm">
					<p>Transmission type</p>
				</div>
				<div className="grid grid-cols-2 gap-1 mb-5">
					{transmissionList.map((item, index) => (
						<div key={index} className="mt-5">
							<label className="flex items-center">
								<input
									type="checkbox"
									className="h-4 w-4 border-[#B8B8B8] mr-4"
									checked={
										criteria?.vehicle_transmission_types &&
										criteria?.vehicle_transmission_types?.indexOf(
											item.label
										) > -1
									}
									onChange={() =>
										handleSelectTransmission(index)
									}
								/>
								<span className="font-base font-medium">
									{item.label}
								</span>
							</label>
						</div>
					))}
				</div>
				<div className="mt-5 text-[#0F172A] text-sm">
					<p>Drivetrain type</p>
				</div>
				<div className="grid grid-cols-2 gap-1">
					{drivetrainList.map((item, index) => (
						<div key={index} className="mt-5">
							<label className="flex items-center">
								<input
									type="checkbox"
									className="h-4 w-4 border-[#B8B8B8] mr-4"
									checked={
										(criteria?.vehicle_drivetrains &&
											criteria?.vehicle_drivetrains?.indexOf(
												item.label
											) > -1) ||
										(item.value === 1 &&
											criteria?.vehicle_drivetrains
												?.length === 3)
									}
									onChange={() =>
										handleSelectDrivetrain(item.value)
									}
								/>
								<span className="font-base font-medium">
									{item.label}
								</span>
							</label>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Drivetrain;
