import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { LuChevronLast, LuChevronFirst } from "react-icons/lu";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import type { RootState } from "@/common/models";
import { SetCriteriaAction } from "@/common/state/criteria/criteriaActions";
import {
	SetPageAction
	// ,SetPageLimitAction
} from "@/common/state/general/generalActions";
import type { IProduct } from "@/common/state/product/productState";
import { formatNumber } from "@/utils/common";

import ProductCard from "../ProductCard";

import "./index.css";

// const MaxRows = 75;
// const RowLimit = 4;

const Pagination = ({ items, toggleToast }: any) => {
	const country = useSelector(
		(state: RootState) => state.app.location.country_code
	);
	const count =
		useSelector((state: RootState) => state.app.product.count) || 0;
	const page =
		useSelector((state: RootState) => state.view.general.page) || 0;
	const pageLimit =
		useSelector((state: RootState) => state.view.general.pageLimit) || 50;

	const distance =
		useSelector(
			(state: RootState) => state.app.criteria.criteria.distance
		) || 0;
	const distance_unit =
		useSelector(
			(state: RootState) => state.app.criteria.criteria.distance_unit
		) || "K";

	const pageCount = Math.ceil(count / pageLimit);
	// const rows = pageLimit / RowLimit;

	const dispatch = useDispatch();

	const setCurrentPage = (page: number) => {
		dispatch(SetPageAction(page));
	};
	const handlePageClick = (e: any, action: any) => {
		e.preventDefault();

		if (action === "first") {
			setCurrentPage(0);
		} else if (action === "prev") {
			if (page > 1) setCurrentPage(page - 1);
		} else if (action === "next") {
			if (page < pageCount - 1) setCurrentPage(page + 1);
		} else {
			setCurrentPage(pageCount - 1);
		}
	};
	const handleIncreaseRadius = (e: any) => {
		e.preventDefault();
		dispatch(
			SetCriteriaAction({
				key: "distance",
				value: distance_unit === "K" ? 100 : 62
			})
		);
	};
	// const onRowsChange = (e: any) => {
	// 	e.preventDefault();
	// 	dispatch(SetPageLimitAction(parseInt(e.target.value, 10) * RowLimit));
	// };
	return (
		<>
			<div
				className="grid grid-cols-4 gap-5 auto-rows-max py-5"
				style={{
					gridTemplateColumns: "repeat(auto-fill, minmax(313px, 1fr))"
				}}
			>
				{items.map((product: IProduct) => (
					<Link
						to={`/vehicles/${country}/${product.id}`}
						key={product?.id}
					>
						<ProductCard
							product={product}
							toggleToast={toggleToast}
						/>
					</Link>
				))}
				{items.length === 0 && (
					<div className="px-5">
						No vehicles found for current filters.
						{((distance_unit === "K" && distance < 100) ||
							(distance_unit === "N" && distance < 62)) && (
							<div>
								<br />
								<a
									onClick={handleIncreaseRadius}
									className="text-accent hover:underline cursor-pointer"
								>
									Click here to increase your search radius.
								</a>
							</div>
						)}
					</div>
				)}
			</div>
			{pageCount > 0 && (
				<div className="flex justify-end items-center gap-8 mt-5">
					<span className="text-[#3B2F66] text-base">
						{formatNumber(page * pageLimit + 1)}-
						{formatNumber(Math.min((page + 1) * pageLimit, count))}{" "}
						of {formatNumber(count)}
					</span>
					{/* <select
						className="text-[#3B2F66] w-[80px] text-base py-2 px-4 border border-[#3B2F66] rounded"
						value={rows}
						onChange={onRowsChange}
					>
						{Array.from({ length: MaxRows }, (_, i) => (
							<option key={i + 1} value={i + 1}>
								{i + 1}
							</option>
						))}
					</select>
					<span className="text-[#3B2F66] text-base">
						Rows per page
					</span> */}
					<div className="flex items-center gap-3">
						<button onClick={(e) => handlePageClick(e, "first")}>
							<LuChevronFirst className="w-6 h-6" />
						</button>
						<button onClick={(e) => handlePageClick(e, "prev")}>
							<GrFormPrevious className="w-6 h-6" />
						</button>
						<button onClick={(e) => handlePageClick(e, "next")}>
							<GrFormNext className="w-6 h-6" />
						</button>
						<button onClick={(e) => handlePageClick(e, "last")}>
							<LuChevronLast className="w-6 h-6" />
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default Pagination;
