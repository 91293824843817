import { useState, useRef } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ReactImageFallback from "react-image-fallback";
import SlickSlider from "react-slick";

import TransparentImage from "@/assets/images/transparent.png";
import CarImage from "@/assets/images/vehicle_placeholder_lg.png";
import type { IProduct } from "@/common/state/product/productState";
import { debug } from "@/utils/common";

const Slider = ({ product }: { product: IProduct }) => {
	const [imageIndex, setImageIndex] = useState<number>(0);
	const slickSliderRef = useRef(null);

	const handlePrev = () => {
		(slickSliderRef?.current as unknown as SlickSlider)?.slickPrev();
	};

	const handleNext = () => {
		(slickSliderRef?.current as unknown as SlickSlider)?.slickNext();
	};
	const handleSlideClick = (index: number) => {
		debug("goto", index);
		(slickSliderRef?.current as unknown as SlickSlider)?.slickGoTo(index);
	};

	var settings = {
		adaptiveHeight: true,
		beforeChange: (current: number, next: number) => setImageIndex(next),

		customPaging: () => (
			<div
				style={{
					padding: "0px"
				}}
			>
				<div className="rounded-full h-[8px] w-[8px] bg-[#DBDBDB]"></div>
			</div>
		),
		// centerMode: true,
		dots: false,
		infinite: true,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1895,
				settings: {
					slidesToShow: 5
				}
			},
			{
				breakpoint: 1620,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 1500,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 1360,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 1310,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 1120,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 960,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 840,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 720,
				settings: {
					dots: true,
					slidesToShow: 1
				}
			},
			{
				breakpoint: 600,
				settings: {
					dots: true,
					slidesToShow: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					dots: true,
					slidesToShow: 1
				}
			}
		],
		slidesToScroll: 1,
		slidesToShow: 6,
		speed: 500
	};

	return (
		<>
			<div className="h-[260px] md:h-[710px] overflow-hidden relative">
				<div className="md:hidden absolute left-[0px] z-10">
					<div
						onClick={handlePrev}
						className="relative w-[40px] h-[40px] border rounded drop-shadow-lg p-4 cursor-pointer shadow-md mt-[115px]"
						style={{
							backgroundColor: "rgba(0, 0, 0, 0.50)",
							color: "white"
						}}
					>
						<FaChevronLeft className="absolute left-[12px] top-[14px] text-[14px]" />
					</div>
				</div>
				<ReactImageFallback
					src={
						product?.thumbs.length > 0
							? product?.thumbs[imageIndex]
							: CarImage
					}
					fallbackImage={CarImage}
					initialImage={TransparentImage}
					alt=""
					className="w-full h-full car-image-background object-cover absolute top-[0px] left-[0px] md:rounded-[8px]"
				/>
				<div className="md:hidden absolute right-[0px]">
					<div
						onClick={handleNext}
						className="relative w-[40px] h-[40px] border rounded drop-shadow-lg p-4 cursor-pointer shadow-md mt-[115px]"
						style={{
							backgroundColor: "rgba(0, 0, 0, 0.50)",
							color: "white"
						}}
					>
						<FaChevronRight className="absolute right-[11px] top-[14px] text-[14px]" />
					</div>
				</div>
			</div>
			<div className="flex justify-center items-center w-full mt-[20px]">
				<div className="flex justify-evenly md:h-[108px] px-2 w-full relative">
					<div className="hidden md:block bg-gradient-to-r from-white w-[180px] h-[108px] absolute left-[0px] z-10">
						<div
							onClick={handlePrev}
							className="relative w-[40px] h-[40px] border rounded drop-shadow-lg p-4 cursor-pointer shadow-md mt-[33px]"
							style={{
								backgroundColor: "rgba(0, 0, 0, 0.50)",
								color: "white"
							}}
						>
							<FaChevronLeft className="absolute left-[12px] top-[14px] text-[14px]" />
						</div>
					</div>
					<SlickSlider
						{...settings}
						className="w-full"
						ref={slickSliderRef}
						arrows={false}
					>
						{product.thumbs.map((slide: string, index: number) => (
							<div key={index} className={`md:h-[108px]`}>
								<div
									className={`hidden md:block w-[180px] md:h-[108px] overflow-hidden relative ${
										index === imageIndex
											? "border-4 border-blue-400"
											: ""
									}`}
									onClick={() => handleSlideClick(index)}
								>
									<ReactImageFallback
										src={slide}
										fallbackImage={CarImage}
										initialImage={TransparentImage}
										alt=""
										width={`100%`}
										height={`100%`}
										className="absolute object-cover transform translate-y-[-50%] translate-x-[-50%] top-[50%] left-[50%]"
									/>
								</div>
							</div>
						))}
					</SlickSlider>
					<div className="hidden md:block bg-gradient-to-l from-white w-[180px] md:h-[108px] absolute right-[0px]">
						<div
							onClick={handleNext}
							className="relative w-[40px] h-[40px] border rounded drop-shadow-lg p-4 cursor-pointer shadow-md ml-[140px] mt-[33px]"
							style={{
								backgroundColor: "rgba(0, 0, 0, 0.50)",
								color: "white"
							}}
						>
							<FaChevronRight className="absolute right-[11px] top-[14px] text-[14px]" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Slider;
