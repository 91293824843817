import { useContext } from "react";
import ReactSlider from "react-slider";
import { Tooltip } from "react-tooltip";

import { VehicleContext } from "@/common/contexts";
import { ArrowLeftIcon, ArrowRightIcon } from "@/components/Elements";
import { debug } from "@/utils/common";

interface LoanTermProps {
	onChangeAction: any;
}

const LoanTerm = ({ onChangeAction }: LoanTermProps) => {
	const { buildParam, setBuildParam } = useContext(VehicleContext);

	const setLoanTerm = (value: any) => {
		if (value[0] > value[1]) {
			value[0] = value[1];
		}

		const param = {
			...buildParam,
			LoanTermMax: value[1],
			LoanTermMin: value[0]
		};
		setBuildParam(param);
	};
	return (
		<div>
			<p className="mb-[5px] text-[12px] font-[500] leading-[120%] tracking-[0.4px]">
				Loan Term
			</p>
			<div className="">
				<div className="flex justify-between text-[12px] text-[#94A3B8] mb-2">
					<p>From</p>
					<p>To</p>
				</div>
				<div className="">
					<ReactSlider
						value={[buildParam.LoanTermMin, buildParam.LoanTermMax]}
						min={12}
						max={96}
						onChange={(value: any) => {
							debug("value: ", value);
							setLoanTerm(value);
							if (onChangeAction) onChangeAction();
						}}
						className="slider-loan-term"
						thumbClassName="slider-loan-term-thumb"
						trackClassName="slider-loan-term-track"
						renderThumb={(props) => {
							return (
								<div
									{...props}
									className={`${props.className} flex justify-center items-center`}
									data-tooltip-id="credit-score"
									data-tooltip-content={`${buildParam.LoanTermMin} - ${buildParam.LoanTermMax}`}
								>
									<span className="flex items-center relative h-full w-full">
										<ArrowLeftIcon />
										<ArrowRightIcon />
									</span>
									{
										<Tooltip
											id="credit-score"
											variant="light"
											className="border drop-shadow-sm"
										/>
									}
								</div>
							);
						}}
					/>
				</div>
				<div className="flex justify-between text-[#0F172A] text-sm mb-2">
					<p>{12}</p>
					<p>{96}</p>
				</div>
				{/* <div className="flex justify-between gap-4">
					<div className="">
						<input
							className="text-[16px] md:text-sm font-medium border w-full rounded-lg border-[#D9D9D9] pl-5 py-2"
							value={buildParam.LoanTermMin}
							onChange={(value: any) => {
								debug("value: ", value);
								setLoanTerm(parseNumber(value));
								if (onChangeAction) onChangeAction();
							}}
						/>
					</div>
					<div className="">
						<input
							className="text-[16px] md:text-sm font-medium border w-full rounded-lg border-[#D9D9D9] pl-5 py-2"
							value={buildParam.LoanTermMax}
							onChange={(value: any) => {
								debug("value: ", value);
								setLoanTerm(parseNumber(value));
								if (onChangeAction) onChangeAction();
							}}
						/>
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default LoanTerm;
