import { useState } from "react";
import NumericInput from "react-numeric-input";
import { useDispatch, useSelector } from "react-redux";
import ReactSlider from "react-slider";

import type { RootState } from "@/common/models";
import { SetCriteriaAction } from "@/common/state/criteria/criteriaActions";
import type { TCriteria } from "@/common/state/criteria/criteriaState";
import { formatMoney, parseMoney, CONSTANTS } from "@/utils/common";

import { ArrowLeftIcon, ArrowRightIcon, PlusIcon } from "../Elements";

const PriceRange = () => {
	const [show, setShow] = useState(true);
	const dispatch = useDispatch();
	const criteria: TCriteria = useSelector(
		(state: RootState) => state.app.criteria.criteria
	);
	// const range = useSelector((state: RootState) => state.app.product.range.price) || [];

	// useEffect(() => {
	// 	setPriceRange(range);
	// }, [range]);
	const setPriceRange = (priceRange: number[]) => {
		if (priceRange[0] > priceRange[1]) {
			priceRange[0] = priceRange[1];
		}
		dispatch(
			SetCriteriaAction({ key: "listing_price", value: priceRange })
		);
	};
	return (
		<div>
			<div
				className="text-base text-accent font-medium relative cursor-pointer pb-3 border-b-2 border-[#C8D1FF] mb-6"
				onClick={() => setShow((value) => !value)}
			>
				Price Range{" "}
				<span
					className={`${
						show ? "active" : ""
					} absolute right-0 top-[5px] text-[#64748B] dropdown-button`}
				>
					<PlusIcon />
				</span>
			</div>
			<div className={`${show ? "" : "closed"} mb-5 dropdown-container`}>
				<div className="flex justify-between text-[12px] text-[#94A3B8]">
					<p>From</p>
					<p className="mr-6">To</p>
				</div>
				<div className="mb-2">
					<ReactSlider
						value={[
							criteria.listing_price[0] || 0,
							criteria.listing_price[1] || 0
						]}
						min={CONSTANTS.minPrice}
						max={CONSTANTS.maxPrice}
						onAfterChange={(value: number[]) => {
							setPriceRange(value);
						}}
						className="slider-price-range"
						thumbClassName="slider-price-range-thumb"
						trackClassName="slider-price-range-track"
						renderThumb={(props: any) => {
							return (
								<div
									{...props}
									className={`${props.className} flex justify-center items-center`}
								>
									<span className="flex items-center relative h-full w-full">
										<ArrowLeftIcon />
										<ArrowRightIcon />
									</span>
								</div>
							);
						}}
					/>
				</div>
				<div className="flex justify-between text-[#0F172A] text-[12px] mb-2">
					<p>{formatMoney(CONSTANTS.minPrice)}</p>
					<p>{formatMoney(CONSTANTS.maxPrice)}</p>
				</div>
				<div className="flex justify-between gap-4">
					<div className="relative max-w-[145px]">
						<NumericInput
							className="text-sm font-medium border w-full rounded-lg border-[#D9D9D9] pl-[35px] pr-4 py-2"
							value={criteria.listing_price[0]}
							// format={formatMoney}
							parse={parseMoney}
							onChange={(value) =>
								setPriceRange([
									value,
									criteria.listing_price[1] || 0
								])
							}
							min={CONSTANTS.minPrice}
							max={
								criteria.listing_price[1] || CONSTANTS.maxPrice
							}
						/>
					</div>
					<div className="relative max-w-[145px]">
						<NumericInput
							className="text-sm font-medium border w-full rounded-lg border-[#D9D9D9] pl-[35px] pr-4 py-2"
							value={criteria.listing_price[1]}
							// format={formatMoney}
							parse={parseMoney}
							onChange={(value) =>
								setPriceRange([
									criteria.listing_price[0] || 0,
									value
								])
							}
							min={
								criteria.listing_price[0] || CONSTANTS.minPrice
							}
							max={CONSTANTS.maxPrice}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PriceRange;
