import _ from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSlider from "react-slider";
import Switch from "react-switch";

import type { RootState } from "@/common/models";
import { SetCriteriaAction } from "@/common/state/criteria/criteriaActions";
import { SetLocationAction } from "@/common/state/location/locationActions";
import { CONSTANTS, debug } from "@/utils/common";

import AutoComplete from "../AutoComplete";
import { ArrowLeftIcon, ArrowRightIcon, PlusIcon } from "../Elements";

const Location = () => {
	const distance =
		useSelector(
			(state: RootState) => state.app.criteria.criteria.distance
		) || 25;
	const distanceUnit =
		useSelector(
			(state: RootState) => state.app.criteria.criteria.distance_unit
		) || "K";
	const criteria = useSelector((state: RootState) => state.app.location);

	const [show, setShow] = useState(true);

	const dispatch = useDispatch();

	const onChangeLocation = (location: any) => {
		const lengthContext = location?.context?.length || 0;

		const countryCode =
			location?.context[lengthContext - 1]["short_code"].toUpperCase();
		const placeName = location["place_name_en-US"];

		debug("location");
		debug(location);

		dispatch(
			SetLocationAction({
				city: placeName,
				country_code: countryCode,
				latitude: location?.geometry?.coordinates[1],
				longitude: location?.geometry?.coordinates[0],
				postal:
					_.isArray(location?.place_type) &&
					location?.place_type[0] === "postcode"
						? location?.text
						: placeName
			})
		);
		dispatch(
			SetCriteriaAction({
				key: "mileage_unit",
				value: countryCode === "CA" ? "K" : "N"
			})
		);
		dispatch(
			SetCriteriaAction({
				key: "distance_unit",
				value: countryCode === "CA" ? "K" : "N"
			})
		);
	};

	return (
		<div className="filter bg-white rounded-lg px-6 py-5 mb-5">
			<h2
				onClick={() => setShow((value) => !value)}
				className={`text-xl text-orange font-bold ${
					show ? "mb-6" : ""
				} relative cursor-pointer`}
			>
				Location{" "}
				<span
					className={`${
						show ? "active" : ""
					} absolute right-0 top-[5px] text-[#64748B] dropdown-button`}
				>
					<PlusIcon />
				</span>
			</h2>
			<div className={`${show ? "" : "closed"} mb-5 dropdown-container`}>
				<div className="relative mb-8">
					<p className="mb-2 text-sm text-[#0F172A]">
						{criteria?.country_code === "CA" ? "Postal" : "Zip"}{" "}
						Code
					</p>
					<AutoComplete
						onChange={onChangeLocation}
						initialLocation={String(criteria?.postal)}
					/>
				</div>
				<div className="mb-4">
					<p className="text-sm text-orange mb-4">Distance</p>
					<div className="mb-4">
						<ReactSlider
							value={distance}
							min={0}
							max={1000}
							onAfterChange={(value) =>
								dispatch(
									SetCriteriaAction({
										key: "distance",
										value: value
									})
								)
							}
							className="slider-distance"
							thumbClassName="slider-distance-thumb"
							trackClassName="slider-distance-track"
							renderThumb={(props) => {
								return (
									<div
										{...props}
										className={`${props.className} flex justify-center items-center`}
									>
										<span className="flex items-center relative h-full w-full">
											<ArrowLeftIcon />
											<ArrowRightIcon />
										</span>
									</div>
								);
							}}
						/>
					</div>
					<div className="relative">
						<input
							className="text-base font-medium border w-full rounded-lg border-[#D9D9D9] px-2 py-[5px]"
							onChange={(e) =>
								dispatch(
									SetCriteriaAction({
										key: "distance",
										value: Number(e.target.value)
									})
								)
							}
							value={`${distance}`}
						/>
						<span className="absolute top-[5px] left-14">
							{distanceUnit === "K" ? "Kilometers" : "Miles"}
						</span>
					</div>
				</div>
				<div className="mb-4">
					<label
						htmlFor="unit-switch"
						className="flex items-center gap-3"
					>
						<span className="text-sm">Miles</span>
						<Switch
							checked={distanceUnit === "K"}
							onChange={() => {
								dispatch(
									SetCriteriaAction({
										key: "distance",
										value:
											distanceUnit === "K"
												? Math.round(
														distance /
															CONSTANTS.kmPerMile
												  )
												: Math.round(
														distance *
															CONSTANTS.kmPerMile
												  )
									})
								);
								dispatch(
									SetCriteriaAction({
										key: "distance_unit",
										value: distanceUnit === "K" ? "N" : "K"
									})
								);
							}}
							onColor="#FF7350"
							offColor="#FFE8E2"
							onHandleColor="#FF7350"
							offHandleColor="#FF7350"
							uncheckedIcon={false}
							checkedIcon={false}
							handleDiameter={21}
							height={20}
							width={44}
							className="switch-miles"
							id="unit-switch"
						/>
						<span className="text-sm">Kilometers</span>
					</label>
				</div>
			</div>
		</div>
	);
};

export default Location;
