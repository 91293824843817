import { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import type { Dispatch, AnyAction } from "redux";

import { ServicesContext } from "@/common/contexts";
import type { RootState } from "@/common/models";
import type { IServices } from "@/common/services/initiate";
import { SetCriteriaListAction } from "@/common/state/criteria/criteriaActions";
import type { CriteriaActionProps } from "@/common/state/criteria/criteriaActions";
import type { CriteriaState } from "@/common/state/criteria/criteriaState";
import { SetPageAction } from "@/common/state/general/generalActions";
import {
	SetCoordinateAction,
	SetLocationAction
} from "@/common/state/location/locationActions";
import type { LocationState } from "@/common/state/location/locationState";
import {
	ListMakesModelsAction,
	ListProductsAction,
	ListTrimsAction,
	SetRangeMileageAction
} from "@/common/state/product/productActions";
import type { IProduct } from "@/common/state/product/productState";
import Redirect from "@/components/Redirect";
import Landing from "@/pages/Landing";
import Vehicles from "@/pages/Vehicles";
import type { ICoordinate } from "@/utils/common";
import { getGeoInfo, CONSTANTS } from "@/utils/common";

import ScrollToTop from "./scrollToTop";

interface RootProps {
	criteria?: CriteriaState;
	location?: LocationState;
	page?: number;
	pageLimit?: number;
	products?: IProduct[];
	listProducts: (param: any) => Promise<IProduct[]>;
	setCurrentPage: (page: number) => void;
	setLocation: (location: LocationState) => void;
	setCoordinate: (c: ICoordinate) => void;
	setCriteriaList: (c: CriteriaActionProps[]) => void;
	listMakesModels: (makes: any) => void;
	listTrims: (params: any) => void;
	setRangeMileage: () => void;
	setPage: (p: number) => void;
}
const Root = (props: RootProps) => {
	const services: IServices | undefined = useContext(ServicesContext);
	const { criteria } = props.criteria as CriteriaState;

	useEffect(() => {
		initiate();
	}, []);

	useEffect(() => {
		if (criteria?.vehicle_makes && criteria.vehicle_makes.length > 0) {
			props.listMakesModels(criteria?.vehicle_makes);
		} else {
			props.setCriteriaList([{ key: "vehicle_models", value: [] }]);
		}
		props.setCriteriaList([{ key: "vehicle_trims", value: [] }]);
	}, [criteria?.vehicle_makes]);

	useEffect(() => {
		if (criteria?.vehicle_models && criteria?.vehicle_models.length > 0) {
			props.listTrims({
				vehicle_makes: criteria?.vehicle_makes,
				vehicle_models: criteria?.vehicle_models
			});
		} else {
			props.setCriteriaList([{ key: "vehicle_trims", value: [] }]);
		}
	}, [criteria?.vehicle_models]);

	const fetchData = async () => {
		if (
			!props.location ||
			!props.location.country_code ||
			!props.location.latitude ||
			!props.location.longitude
		) {
			return;
		}
		try {
			services?.loading.actions.start();
			await getProducts(
				props.page || 0,
				props.pageLimit || CONSTANTS.itemsPerPage
			);
		} catch (error) {
			// handle error
		} finally {
			services?.loading.actions.stop();
		}
	};
	useEffect(() => {
		fetchData();
	}, [criteria, props.pageLimit, props.location, props.page]);

	const getProducts = async (page: number, pageLimit: number) => {
		let _criteria: any = Object.assign({}, criteria) as any;
		_criteria = {
			..._criteria,
			listing_mileage_max: _criteria.listing_mileage[1],
			listing_mileage_min: _criteria.listing_mileage[0],
			listing_price_max: _criteria.listing_price[1],
			listing_price_min: _criteria.listing_price[0],
			vehicle_year_max: _criteria.vehicle_year[1],
			vehicle_year_min: _criteria.vehicle_year[0]
		};
		if (_criteria.mileage_unit === "K") {
			_criteria.listing_mileage_min = Math.round(
				_criteria.listing_mileage_min / CONSTANTS.kmPerMile
			);
			_criteria.listing_mileage_max = Math.round(
				_criteria.listing_mileage_max / CONSTANTS.kmPerMile
			);
		}
		if (
			_criteria?.vehicle_engine_cylinders &&
			_criteria?.vehicle_engine_cylinders?.indexOf("All") > -1
		) {
			_criteria = {
				..._criteria,
				vehicle_engine_cylinders: []
			};
		}
		if (
			_criteria?.vehicle_fuel_types &&
			_criteria?.vehicle_fuel_types?.indexOf("All types") > -1
		) {
			_criteria = {
				..._criteria,
				vehicle_fuel_types: []
			};
		}
		if (
			_criteria?.vehicle_drivetrains &&
			_criteria?.vehicle_drivetrains?.indexOf("All") > -1
		) {
			_criteria = {
				..._criteria,
				vehicle_drivetrains: []
			};
		}
		await props.listProducts({
			..._criteria,
			country_code: props.location?.country_code,
			location: [props.location?.longitude, props.location?.latitude],
			page: page,
			page_limit: pageLimit
		});
	};
	const initiate = async () => {
		// set geo info
		if (!props.location?.city) {
			const info = await getGeoInfo();
			await props.setLocation(info);
			const unit = info.country_code === "CA" ? "K" : "N";
			await props.setCriteriaList([
				{ key: "distance_unit", value: unit },
				{ key: "mileage_unit", value: unit }
			]);
		}
		// get model list
		await props.listMakesModels("");
	};
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Landing />} />
				<Route path="/vehicles/:country/:id" element={<Vehicles />} />
				<Route
					path="/vehicles/"
					element={<Navigate replace to="/" />}
				/>
				<Route
					path="/dealers/"
					element={
						<Redirect to={"https://corp.carbeeza.com/dealers"} />
					}
				/>
			</Routes>
		</BrowserRouter>
	);
};

const mapStateToProps = (state: RootState) => ({
	criteria: state.app.criteria,
	location: state.app.location,
	page: state.view.general.page,
	pageLimit: state.view.general.pageLimit,
	products: state.app.product.products
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
	listMakesModels: bindActionCreators(ListMakesModelsAction, dispatch),
	listProducts: bindActionCreators(ListProductsAction, dispatch),
	listTrims: bindActionCreators(ListTrimsAction, dispatch),
	setCoordinate: bindActionCreators(SetCoordinateAction, dispatch),
	setCriteriaList: bindActionCreators(SetCriteriaListAction, dispatch),
	setCurrentPage: bindActionCreators(SetPageAction, dispatch),
	setLocation: bindActionCreators(SetLocationAction, dispatch),
	setPage: bindActionCreators(SetPageAction, dispatch),
	setRangeMileage: bindActionCreators(SetRangeMileageAction, dispatch)
	// setRangePrice: bindActionCreators(SetRangePriceAction, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Root);
