import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSlider from "react-slider";
import Switch from "react-switch";

import type { RootState } from "@/common/models";
import { SetCriteriaAction } from "@/common/state/criteria/criteriaActions";
import { SetLocationAction } from "@/common/state/location/locationActions";
import ButtonGroup from "@/components/ButtonGroup";
import { ArrowLeftIcon, ArrowRightIcon } from "@/components/Elements";
import type { MapContainerRef } from "@/components/MapContainer";
import MapContainer from "@/components/MapContainer";
import { CONSTANTS } from "@/utils/common";

interface ChangeLocationFormProps {
	toggleModal: () => void;
}

export default function ChangeLocationForm({
	toggleModal
}: ChangeLocationFormProps) {
	const dispatch = useDispatch();
	const [milesCheck, setMilesCheck] = useState(false);
	const [countryCheck, setCountryCheck] = useState(true);
	const [appliable, setAppliable] = useState(false);
	const mapContainer = useRef<MapContainerRef>(null);
	const initDistance =
		useSelector(
			(state: RootState) => state.app.criteria.criteria.distance
		) || 0;
	const initDistanceUnit =
		useSelector(
			(state: RootState) => state.app.criteria.criteria.distance_unit
		) || "K";
	const initCountryCode = useSelector(
		(state: RootState) => state.app.location.country_code
	);
	const [distance, setDistance] = useState(1);
	const [postal, setPostal] = useState("");
	const [radius, setRadius] = useState(0);
	const [isMoving, setMoving] = useState(false);

	useEffect(() => {
		setDistance(initDistance);
	}, [initDistance]);
	useEffect(() => {
		setMilesCheck(initDistanceUnit === "N");
	}, [initDistanceUnit]);
	useEffect(() => {
		setCountryCheck(initCountryCode !== "CA");
	}, [initCountryCode]);

	const handleMilesCheck = (value: any) => {
		setDistance(
			value
				? Math.round(distance * CONSTANTS.kmPerMile)
				: Math.round(distance / CONSTANTS.kmPerMile)
		);
		setMilesCheck(!value);
	};
	const handleCountryCheck = (value: any) => {
		setCountryCheck(!value);
		setMilesCheck(!value);
	};

	useEffect(() => {
		setRadius(distance * (milesCheck ? 1609.34 : 1000));
	}, [distance, milesCheck]);

	const handleApply = () => {
		if (mapContainer.current && appliable) {
			toggleModal?.();
			mapContainer.current?.confirm();
			dispatch(
				SetLocationAction({
					country_code: countryCheck ? "US" : "CA",
					postal: postal
				})
			);
			dispatch(
				SetCriteriaAction({
					key: "distance",
					value: distance
				})
			);
			const distanceUnit = milesCheck ? "N" : "K";
			dispatch(
				SetCriteriaAction({
					key: "distance_unit",
					value: distanceUnit
				})
			);
			dispatch(
				SetCriteriaAction({
					key: "mileage_unit",
					value: distanceUnit
				})
			);
			setAppliable(false);
		}
	};
	const onChangeLocation = (countryCode: string, postCode: string) => {
		setAppliable(true);
		setPostal(postCode);
		setDistance(countryCode !== "CA" ? 16 : 25);
		setCountryCheck(countryCode !== "CA");
		setMilesCheck(countryCode !== "CA");
	};

	return (
		<form onClick={(e) => e.preventDefault()}>
			<div className="flex justify-between align-top mt-6 md:mt-10">
				<div className="flex flex-col justify-center align-middle gap-[10px]">
					<p className="self-stretch text-sm">Units</p>
					<div className="mb-4">
						<label
							htmlFor="unit-switch"
							className="flex items-center gap-3"
						>
							<span className="text-sm">Miles</span>
							<Switch
								checked={!milesCheck}
								onChange={(value) => {
									handleMilesCheck(value);
									setAppliable(true);
								}}
								onColor="#FFE8E2"
								offColor="#FFE8E2"
								onHandleColor="#FF7350"
								offHandleColor="#FF7350"
								uncheckedIcon={false}
								checkedIcon={false}
								handleDiameter={21}
								height={20}
								width={44}
								id="unit-switch"
							/>
							<span className="text-sm">Kilometers</span>
						</label>
					</div>
				</div>
				<div className="flex flex-col justify-center align-middle gap-[10px]">
					<p className="self-stretch text-sm">Country</p>
					<div className="mb-4">
						<label
							htmlFor="country-switch"
							className="flex items-center gap-3"
						>
							<span className="text-sm">US</span>
							<Switch
								checked={!countryCheck}
								onChange={(value) => {
									handleCountryCheck(value);
									setAppliable(true);
								}}
								onColor="#FFE8E2"
								offColor="#FFE8E2"
								onHandleColor="#FF7350"
								offHandleColor="#FF7350"
								uncheckedIcon={false}
								checkedIcon={false}
								handleDiameter={21}
								height={20}
								width={44}
								id="country-switch"
							/>
							<span className="text-sm">Canada</span>
						</label>
					</div>
				</div>
			</div>
			<div className="my-6 md:my-10 h-[320px] md:h-[400px] bg-slate-500 relative">
				<MapContainer
					ref={mapContainer}
					radius={radius}
					onChange={onChangeLocation}
				/>
			</div>
			<p className="text-sm text-orange mb-6">Distance</p>
			<div className="mb-4">
				<ReactSlider
					value={distance}
					min={0}
					max={1000}
					onAfterChange={(value) => {
						setDistance(value);
						setAppliable(true);
						setMoving(false);
					}}
					onBeforeChange={() => setMoving(true)}
					className="slider-distance"
					thumbClassName="slider-distance-thumb"
					trackClassName="slider-distance-track"
					renderThumb={(props) => {
						return (
							<div
								{...props}
								className={`${props.className} flex justify-center items-center`}
							>
								<span className="flex items-center relative h-full w-full">
									<ArrowLeftIcon />
									<ArrowRightIcon />
								</span>
								{isMoving && (
									<div className="absolute top-[-30px] w-[94px] h-[31px] shrink-0 m-0 p-0 flex flex-col justify-start align-middle fill-white stroke-[#EEF2F6] stroke-1 drop-shadow-md drop-shadow-[rgba(0, 0, 0, 0.20)] after:content-['▼'] after:text-md after:text-white after:relative after:-top-2">
										<div className="m-0 w-full h-[24px] align-middle align-center bg-white rounded-sm">
											<span className="text-xs text-black whitespace-nowrap">
												{distance}{" "}
												{milesCheck
													? "Miles"
													: "Kilometers"}
											</span>
										</div>
									</div>
								)}
							</div>
						);
					}}
				/>
			</div>
			<ButtonGroup className="gap-6 mt-4 md:mt-10">
				<button
					onClick={() => {
						setAppliable(false);
						toggleModal?.();
					}}
					className="rounded-lg border border-[#E2DEDD] bg-[#EFEBE9] w-[100px] md:w-[125px] h-[40px] md:h-[60px] text-[#84736F] text-lg md:text-xl font-medium"
				>
					Cancel
				</button>
				<button
					disabled={!appliable}
					className="rounded-lg border border-[rgba(130, 100, 65, 0.10)] bg-[#FFD750] w-[100px] md:w-[125px] h-[40px] md:h-[60px] text-[#826441] text-lg md:text-xl font-medium disabled:text-[#BAABA7] disabled:bg-[#EFEBE9]"
					onClick={handleApply}
				>
					Apply
				</button>
			</ButtonGroup>
		</form>
	);
}
