import _ from "lodash";

import { apiGetRequest, apiPostRequest } from "@/utils/apiRequests";

const API_URL = import.meta.env.VITE_API_URL;

export const getVehicles = _.throttle(
	(params: any) => {
		// export const getVehicles = (params: any) => {
		return apiPostRequest(`${API_URL}/vehicles`, params);
		// };
	},
	50,
	{ leading: true, trailing: false }
);

export const getVehicle = (params: any) => {
	const { country, id } = params;
	return apiGetRequest(`${API_URL}/vehicle?country_code=${country}&id=${id}`);
};

// export const getPriceRange = () => {
// 	return apiGetRequest(`${API_URL}/price-range`);
// };

// export const getYearRange = () => {
// 	return apiGetRequest(`${API_URL}/year-range`);
// };

export const getMileageRange = () => {
	return apiGetRequest(`${API_URL}/mileage-range`);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMakesAndModels = (makes?: any) => {
	return apiGetRequest(`${API_URL}/makes-models`);
};

export const getTrimList = (params: any) => {
	return apiPostRequest(`${API_URL}/trims`, params);
};

export const sendEmail = (params: any) => {
	return apiPostRequest(`${API_URL}/send-email`, params);
};

export const getEstimatedPayment = (params: any) => {
	return apiPostRequest(`${API_URL}/estimated-payment`, params);
};

export const lookupVHR = (params: any) => {
	return apiPostRequest(`${API_URL}/lookup-vhr`, params);
};

export const sendToUltralead = (params: any) => {
	return apiPostRequest(`${API_URL}/send-to-ultralead`, params);
};
