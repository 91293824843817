interface RedirectProps {
	to: string;
}
/**
 *
 * @param to
 * @returns redirects outside of the app
 */
const Redirect = ({ to }: RedirectProps) => {
	window.location.href = to;
	return <div></div>;
};

export default Redirect;
