import axios from "axios";

import { debug } from "@/utils/common";

export const instance = axios.create();

instance.interceptors.request.use(
	(config) => {
		if (config.url) {
			const timestamp = Date.now();
			const key: string = config.url;
			config.data = { ...config.data, timestamp };
			sessionStorage.setItem(key, timestamp.toString());
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		if (response?.config?.url) {
			const key: string = response.config.url;
			const value = sessionStorage.getItem(key);
			if (!value) {
				throw new axios.Cancel("Operation canceled by the user.");
			}
			const localTimestamp = Number.parseInt(value, 10);
			const responseTimestamp = JSON.parse(
				response?.config?.data
			).timestamp;
			if (responseTimestamp >= localTimestamp) {
				sessionStorage.removeItem(key);
			}
		}
		return response;
	},
	(error) => {
		if (error?.config?.url) {
			const key: string = error.config.url;
			const value = sessionStorage.getItem(key);
			if (!value) {
				throw new axios.Cancel("Operation canceled by the user.");
			}
			const localTimestamp = Number.parseInt(value, 10);
			const responseTimestamp = JSON.parse(error?.config?.data).timestamp;
			if (responseTimestamp >= localTimestamp) {
				sessionStorage.removeItem(key);
			}
		}
		return Promise.reject(error);
	}
);

export const apiGetRequest = async (url: string) => {
	try {
		const response = await instance.get(url);
		if (response?.data?.error === "Unauthorized") {
			// store.dispatch(actions.logout());
		} else {
			return response;
		}
	} catch (error: any) {
		debug(error);
		if (error?.response?.status === 401) {
			// store.dispatch(actions.logout());
			return error?.response;
		} else {
			return error?.response;
		}
	}
};

export const apiPostRequest = async (url: string, data = {}, headers = {}) => {
	try {
		const response = await instance.post(url, data, { headers });
		return response;
	} catch (error: any) {
		debug(error);
		if (error?.response?.status === 401) {
			// store.dispatch(actions.logout());
			return error.response;
		} else {
			return error.response;
		}
	}
};
