// import fileDownload from 'js-file-download'
import { useEffect, useState } from "react";
import { FiExternalLink } from "react-icons/fi";

import { lookupVHR } from "@/common/services/apiService";
import type { IProduct } from "@/common/state/product/productState";
import { debug } from "@/utils/common";

export interface OverviewAndFeaturesProps {
	product: IProduct;
}

const OverviewAndFeatures = (props: OverviewAndFeaturesProps) => {
	const { product } = props;

	const [reports, setReports] = useState<any[]>([]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [token, setToken] = useState(null);

	useEffect(() => {
		if (props.product) {
			updateVHR(props.product.vin);
		}
	}, [props.product]);

	const updateVHR = async (vin: string) => {
		const params = { vin: vin };
		lookupVHR(params)
			.then((res: any) => {
				setToken(res?.data?.token);
				setReports(res?.data?.ResponseData?.Reports);
			})
			.catch(() => {
				debug("response error");
			});
	};

	/*const openVhrPdf = async () => {
    const url = reports[0].ReportLinkPDF
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/pdf',
    }
    fetch(url, { headers }).then(async (response) => {
      if (response.ok) {
        const blob = await response.blob()
        fileDownload(blob, 'VHR_Report.pdf')
      }
    })
  }*/

	return (
		<div className="leading-[160%] tracking-[0.4px] ">
			<p className="text-[24px] md:text-[32px] mt-[0px] pb-[12px] mb-[28px] font-[600] text-[#0F172A] border-b-[1px] border-[#D9D9D9] md:border-none">
				Overview & Features
			</p>
			<p className="text-[24px] md:text-[28px] md:pb-[46px] text-[#0F172A]  md:border-b-[1px] border-[#D9D9D9] md:mb-[46px] mb-[32px]">
				Overview
			</p>
			<div className="grid md:grid-cols-4 gap-[20px] mb-[32px] md:mb-0">
				<div>
					<p className="text-accent font-circular-std text-[20px] font-medium mb-[24px]">
						Engine & Performance
					</p>
					<ul className="font-[450]">
						<p className="font-normal leading-8">
							{props.product.details.vehicle_engine}
						</p>
						<p className="font-normal leading-8">
							{props.product.details.vehicle_transmission}
						</p>
						<p className="font-normal leading-8">
							{props.product.details.drivetrain}
						</p>
						<p className="font-normal leading-8">
							{props.product.details.vehicle_fuel_efficiency}
						</p>
					</ul>
				</div>
				<div>
					<p className="text-accent font-circular-std text-[20px] font-medium mb-[24px]">
						Colors
					</p>
					<ul className="font-[450]">
						<p className="font-normal leading-8">
							Exterior:{" "}
							{props.product.details.vehicle_color_exterior}
						</p>
						<p className="font-normal leading-8">
							Interior:{" "}
							{props.product.details.vehicle_color_interior}
						</p>
					</ul>
				</div>
				<div>
					<p className="text-accent font-circular-std text-[20px] font-medium mb-[24px]">
						Body
					</p>
					<ul className="font-[450]">
						<p className="font-normal leading-8">
							{props.product.details.vehicle_type}
						</p>
						<p className="font-normal leading-8">
							{props.product.details.vehicle_doors}
						</p>
					</ul>
				</div>
				<div></div>
			</div>
			<hr className="my-[46px] border-[#D9D9D9] hidden md:block" />
			<div className="grid md:grid-cols-4 gap-[20px] mb-[32px] md:mb-0">
				<div>
					<p className="text-accent font-circular-std text-[20px] font-medium w-full mb-[24px]">
						Comfort & Convenience
					</p>
					<ul>
						<p className="font-normal leading-8">
							Bed Style:{" "}
							{props.product.details.vehicle_truck_bed_style}
						</p>
						<p className="font-normal leading-8">
							Truck Style:{" "}
							{props.product.details.vehicle_truck_cab_style}
						</p>
					</ul>
				</div>
				<div>
					<p className="text-accent font-circular-std text-[20px] font-medium w-full mb-[24px]">
						Engine
					</p>
					<ul>
						<p className="font-normal leading-8">
							Fuel: {props.product.details.vehicle_fuel_type}
						</p>
						<p className="font-normal leading-8">
							Cylinder:{" "}
							{props.product.details.vehicle_engine_cylinders}
						</p>
						<p className="font-normal leading-8">
							Size: {props.product.details.vehicle_engine_size}
						</p>
					</ul>
				</div>
				<div>
					<p className="text-accent font-circular-std text-[20px] font-medium w-full mb-[24px]">
						Exterior
					</p>
					<ul>
						<p className="font-normal leading-8">Fog Lights</p>
						<p className="font-normal leading-8">
							Heated Door Mirrors
						</p>
						<p className="font-normal leading-8">Premium Wheels</p>
					</ul>
				</div>
				<div>
					<p className="text-accent font-circular-std text-[20px] font-medium w-full mb-[24px]">
						Infotainment
					</p>
					<ul>
						{product.details.listing_features &&
							product.details?.listing_features
								.split(",")
								.map((item: string, key: number) => (
									<p
										className="font-normal leading-8"
										key={key}
									>
										{item}
									</p>
								))}
					</ul>
				</div>
			</div>
			<hr className="my-[46px] border-[#D9D9D9] hidden md:block" />
			<div className="grid md:grid-cols-4 gap-[20px] mb-[32px] md:mb-0">
				<div>
					<p className="text-accent font-circular-std text-[20px] font-medium w-full mb-[24px]">
						Interior
					</p>
					<ul>
						<p className="font-normal leading-8">
							Coming Home Device
						</p>
						<p className="font-normal leading-8">
							Keyless Entry/Locking
						</p>
						<p className="font-normal leading-8">
							Keyless Start/Remote Engine Start
						</p>
						<p className="font-normal leading-8">
							Power Closing Doors
						</p>
						<p className="font-normal leading-8">
							Power Closing Liftgate
						</p>
					</ul>
				</div>
				<div>
					<p className="text-accent font-circular-std text-[20px] font-medium w-full mb-[24px]">
						Safety & Driver Assist
					</p>
					<ul>
						<p className="font-normal leading-8">Electric</p>
					</ul>
				</div>
				<div>
					<p className="text-accent font-circular-std text-[20px] font-medium w-full mb-[24px]">
						Transmission
					</p>
					<ul>
						<p className="font-normal leading-8">
							{props.product.details.vehicle_transmission_type}
						</p>
						<p className="font-normal leading-8">
							{props.product.details.vehicle_transmission_speed}
						</p>
					</ul>
				</div>
				<div></div>
			</div>
			{reports?.length > 0 && reports[0].ReportLinkUrl && (
				<>
					<hr className="my-[46px] border-[#D9D9D9] hidden md:block" />
					<section className="grid grid-cols-none md:grid-cols-3 gap-[8px]">
						<div>
							<p className="text-accent text-[20px] font-medium w-full mb-[24px]">
								Carfax Report
							</p>
							<p className="font-normal leading-8 text-[#5E78FF]">
								<a
									href={reports[0].ReportLinkUrl}
									target="_blank"
								>
									View Carfax Report for this Vehicle{" "}
									<FiExternalLink className="inline" />
								</a>
								{/* <button onClick={openVhrPdf}>(PDF)</button> */}
							</p>
						</div>
					</section>
				</>
			)}
			<hr className="my-[46px] border-[#D9D9D9] hidden md:block" />
			<p className="text-[32px] md:text-[28px] mb-[23px] text-[#4A495C] font-[500]">
				Seller Details
			</p>
			<section className="grid lg-grid-cols-4 gap-[8px]">
				<div>
					<p className="text-accent text-[20px] font-medium mt-[8px] mb-[20px]">
						<a
							href={`${product.details.listing_vdp_url}`}
							target="_blank"
						>
							{props.product.details.va_seller_name}{" "}
							<FiExternalLink className="inline mb-[4px]" />
						</a>
					</p>
					<ul className="font-[450]">
						<p className="font-normal">
							{props.product.details.va_seller_address}
							<br />
							{`${props.product.details.va_seller_city}, ${props.product.details.va_seller_state}`}
							<br />
							{props.product.details.va_seller_phones &&
								props.product.details.va_seller_phones
									.split(",")
									.join(", ")}
						</p>
					</ul>
				</div>
			</section>
		</div>
	);
};

export default OverviewAndFeatures;
