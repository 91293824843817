import {
	getMakesAndModels,
	getVehicles,
	getTrimList,
	getMileageRange,
	// getPriceRange,
	getVehicle,
	getEstimatedPayment
} from "@/common/services/apiService";
// import httpService from '@/common/services/httpService'
import createAsyncAction from "@/utils/createAsyncAction";

export enum ProductActionTypes {
	LIST_PRODUCTS = "LIST_PRODUCTS",
	LIST_MAKES_MODELS = "LIST_MAKES_MODELS",
	LIST_TRIMS = "LIST_TRIMS",
	SET_RANGE_MILEAGE = "SET_RANGE_MILEAGE",
	// SET_RANGE_PRICE = "SET_RANGE_PRICE",
	GET_PRODUCT = "GET_PRODUCT",
	CLEAR_PRODUCT = "CLEAR_PRODUCT",
	GET_ESTIMATED_PAYMENT = "GET_ESTIMATED_PAYMENT"
}

export const GetProductAction: (params: any) => Promise<any> =
	createAsyncAction(ProductActionTypes.GET_PRODUCT, (params) => {
		return getVehicle(params);
	});

export const ClearProductAction = () => ({
	type: ProductActionTypes.CLEAR_PRODUCT
});

export const ListProductsAction: (params: any[]) => Promise<any[]> =
	createAsyncAction(ProductActionTypes.LIST_PRODUCTS, (params) => {
		return getVehicles(params);
	});

export const ListMakesModelsAction: (makes: any) => Promise<any[]> =
	createAsyncAction(ProductActionTypes.LIST_MAKES_MODELS, (makes: any) => {
		return getMakesAndModels(makes);
	});

export const ListTrimsAction: (params: any) => Promise<any[]> =
	createAsyncAction(ProductActionTypes.LIST_TRIMS, (params) => {
		return getTrimList(params);
	});

export const SetRangeMileageAction: () => Promise<any[]> = createAsyncAction(
	ProductActionTypes.SET_RANGE_MILEAGE,
	() => {
		return getMileageRange();
	}
);
// export const SetRangePriceAction: () => Promise<any[]> = createAsyncAction(
// 	ProductActionTypes.SET_RANGE_PRICE,
// 	() => {
// 		return getPriceRange();
// 	}
// );

export const GetEstimatedPaymentAction: (params: any[]) => Promise<any[]> =
	createAsyncAction(ProductActionTypes.GET_ESTIMATED_PAYMENT, (params) => {
		return getEstimatedPayment(params);
	});
