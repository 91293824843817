import { BiMenuAltRight } from "react-icons/bi";
import { Link } from "react-router-dom";

import Logo from "../assets/autoyaya-logo.svg";
import Disclaimer from "../disclaimer.svg";

const Header = ({ logo }: any) => {
	const onClick = () => {
		document.querySelector("#sidebar")?.classList.toggle("hidden");
		// document.querySelector("#payment")?.classList.toggle("hidden");
		// document.querySelector('#collapse')?.classList.toggle('hidden')
	};
	return (
		<>
			<div className="flex flex-0 justify-start items-start md:items-center bg-[#FFE691] border-b-4 border-b-[#BA9624] py-1">
				<div className="hidden pl-12 pr-4 py-2 md:py-5 z-50 md:inline-block sm:flex-0">
					<img src={Disclaimer} alt="Disclaimer" />
				</div>
				<div className="px-4 py-2 flex-1 text-xs sm:text-base text-[#483A0C] font-sans">
					<span className="font-semibold">
						Welcome to AutoYaYa Marketplace Beta!
					</span>{" "}
					<span className="text-xs sm:text-base tracking-tight">
						Please note: some features are in development, with many
						undergoing active improvements. Your patience and
						feedback are greatly valued!
					</span>
				</div>
			</div>
			<header className="header flex flex-0 justify-between items-center">
				<div className="flex px-12 py-6">
					<Link to="/" className="logo">
						<img src={logo ? Logo : Logo} alt="Logo" />
					</Link>
				</div>
				{window.location.pathname === "/" && (
					<button
						className="lg:hidden text-white px-12 z-50"
						onClick={onClick}
					>
						<BiMenuAltRight size={24} />
					</button>
				)}
			</header>
		</>
	);
};

export default Header;
