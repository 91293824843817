import _ from "lodash";

export const truncate = (str: string) => {
	if (str.length > 10) return str.substring(0, 10);
};

export const CONSTANTS = {
	itemsPerPage: 48,
	kmPerMile: 1.609,
	maxPrice: 120000,
	minPrice: 10000
};

export interface ICoordinate {
	latitude?: number;
	longitude?: number;
}
export const getCoordinates: () => Promise<
	ICoordinate | undefined
> = async () => {
	const geolocationAPI = navigator.geolocation;

	if (!geolocationAPI) {
		debug("Geolocation API is not available in your browser!");
	} else {
		return new Promise<ICoordinate>((resolve) => {
			geolocationAPI.getCurrentPosition(
				(position) => {
					const { coords } = position;
					resolve({
						latitude: coords.latitude,
						longitude: coords.longitude
					} as ICoordinate);
				},
				(error: any) => {
					debug(
						"Something went wrong getting your position!",
						error.message
					);
					resolve({ latitude: 0, longitude: 0 });
				}
			);
		});
	}
};
/**
 *
 * @returns
 * city
 * region
 * region_code
 * country
 * country_name
 * country_code
 * country_capital
 * postal
 * latitude
 * longitude
 * timezone
 * asn
 */
export const getGeoInfo: () => Promise<any> = () =>
	new Promise(async (resolve) => {
		fetch("https://ipapi.co/json/")
			.then(function (response) {
				response.json().then((jsonData) => {
					resolve(jsonData);
				});
			})
			.catch(function (error) {
				// debug(error)
				resolve(error);
			});
	});

export const checkArrayEmpty = (arr: any[] | undefined): boolean => {
	if (arr === undefined) return true;
	const length = arr.length;

	for (let i = 0; i < length; i++) {
		const item = arr[i];

		if (typeof item === "string" && item.length > 0) return false;
		else if (typeof item === "number" && item > 0) return false;
		else if (
			typeof item === "object" &&
			Array.isArray(item) &&
			item.length > 0
		) {
			item.forEach((value) => {
				if (typeof value === "string" && value.length > 0) return false;
				else if (typeof value === "number" && value > 0) return false;
				else return true;
			});
		} else return true;
	}
	return true;
};

export const isEmpty = (value: any) => {
	if (value === undefined) return true;
	if (_.isNumber(value)) {
		return value === 0;
	} else if (_.isString(value)) {
		return value === "";
	} else {
		return _.isEmpty(value);
	}
};

export const getResolutionMap = (lat: number, zoom: number) => {
	// https://wiki.openstreetmap.org/wiki/Zoom_levels
	return (78271.515 * Math.cos((lat * Math.PI) / 180)) / Math.pow(2, zoom);
};

export const formatNumber = (value: number | null) => {
	value = _.isNumber(value) && !_.isNaN(value) ? value : 0;
	return new Intl.NumberFormat().format(value || 0);
};

export const parseNumber = (value: string) => {
	return value ? Number(value.replace(/,/g, "")) : null;
};

export const formatMoney = (value: number | null) => {
	return `$ ${formatNumber(value || 0)}`;
};

export const parseMoney = (value: string) => {
	if (value) {
		if (value.startsWith("$")) {
			value = value.slice(1);
		}
		return parseNumber(value);
	}
	return null;
};

export const debug = (...args: any[]) => {
	if (import.meta.env.VITE_NODE_ENV === "dev") {
		console.log(args);
	}
};

export const postalFormat = (input: any) => {
	if (!input) return input;
	const postalCode = input.toUpperCase().replace(/\W/g, "");
	const postalCodeLength = postalCode.length;
	if (postalCodeLength < 4) return postalCode;
	if (postalCodeLength < 7) {
		return `${postalCode.slice(0, 3)} ${postalCode.slice(3)}`;
	}
	return `${postalCode.slice(0, 3)} ${postalCode.slice(3, 6)}`;
};
export const zipFormat = (input: any) => {
	if (!input) return input;
	const zipCode = input.replace(/[^\d]/g, "");
	const zipCodeLength = zipCode.length;
	if (zipCodeLength < 6) return zipCode;
	return `${zipCode.slice(0, 5)}`;
};

// Some helper functions for data validation.
export const isValidEmail = (email: string) => {
	return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
		email
	);
};
export const isValidPostal = (postal: string, strict: boolean = false) => {
	if (strict) {
		return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
			postal
		);
	} else {
		return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d?[ABCEGHJ-NPRSTV-Z]?\d?$/i.test(
			postal
		);
	}
};

export const isValidZip = (zip: string) => {
	return /^\d{5}(?:[-\s]\d{4})?$/i.test(zip);
};
