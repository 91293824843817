export const SearchIcon = () => (
	<svg
		className="shrink-0"
		xmlns="http://www.w3.org/2000/svg"
		width={18}
		height={18}
		viewBox="0 0 18 18"
		fill="none"
	>
		<ellipse
			cx="8.66438"
			cy="8.46931"
			rx="7.66438"
			ry="7.66438"
			stroke="black"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.9951 14.1981L17 17.1952"
			stroke="black"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
