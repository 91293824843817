import { useSelector } from "react-redux";
import Select from "react-select";

import type { RootState } from "@/common/models";

export interface Option {
	value: number;
	label: string;
}
interface DropdownProps {
	options: Option[];
	value?: Option;
	className?: string;
	onChange?: (value: Option) => void;
}

const Dropdown = ({ options, value, className, onChange }: DropdownProps) => {
	const criteria = useSelector((state: RootState) => state.app.criteria);
	return (
		<Select
			value={value}
			// defaultValue={options[0]}
			defaultValue={options.find(
				(e) => e.value === (criteria.criteria.sort || "price_asc")
			)}
			options={options}
			onChange={(value: any) => {
				onChange?.(value!);
			}}
			isSearchable={false}
			className={`${className} w-45 z-20`}
		/>
	);
};
export default Dropdown;
