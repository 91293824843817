import _ from "lodash";
import mapboxgl from "mapbox-gl";
import type { Ref } from "react";
import {
	useEffect,
	useRef,
	useState,
	forwardRef,
	useImperativeHandle
} from "react";
import { ImPlus, ImMinus } from "react-icons/im";
import type { MapRef } from "react-map-gl";
// eslint-disable-next-line import/no-named-as-default
import Map, { Layer, Source } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import type { RootState } from "@/common/models";
import { SetLocationAction } from "@/common/state/location/locationActions";
import { getResolutionMap, debug } from "@/utils/common";

import GeocoderControl from "./geocoder-control";

export interface MapContainerRef {
	confirm: () => void;
}
interface MapContainerProps {
	radius: number;
	onChange: (countryCode: string, postCode: string) => void;
}

const MapContainer = forwardRef<MapContainerRef, MapContainerProps>(
	(props, ref: Ref<any>) => {
		const location = useSelector((state: RootState) => state.app.location);
		const dispatch = useDispatch();
		const map = useRef<MapRef | null>(null);
		const mapContainer = useRef(null);
		const TOKEN = mapboxgl.accessToken;

		const city = location?.city || "";
		const lng = location?.longitude || 0;
		const lat = location?.latitude || 0;
		const country_code = location?.country_code || "CA";

		const [zoom, setZoom] = useState(9);
		const [viewState, setViewState] = useState({
			city: city,
			countryCode: country_code,
			latitude: lat,
			longitude: lng,
			postCode: "",
			zoom: zoom
		});

		const [radius, setRadius] = useState(0);
		useEffect(() => {
			setRadius(
				props.radius / getResolutionMap(viewState.latitude, zoom)
			);
		}, [props.radius, viewState.latitude, zoom]);

		const handleChangeLocation = (evt: any) => {
			// evt.result.geometry.coordinates [lng, lat]
			// evt.result.place_name
			// evt.result.context[length].short_code
			debug("", evt);
			const lengthContext = evt.result.context.length;
			const countryCode = _.upperCase(
				evt.result.context[lengthContext - 1]["short_code"]
			);

			const postCodeContext = _.find(evt.result.context, function (item) {
				return _.startsWith(item.id, "postcode");
			});
			const postCode = postCodeContext?.text ? postCodeContext?.text : "";

			props.onChange(countryCode, postCode);
			setViewState((prevState) => ({
				...prevState,
				city: evt.result.place_name,
				countryCode: countryCode,
				latitude: evt.result.geometry.coordinates[1],
				longitude: evt.result.geometry.coordinates[0],
				postCode: postCode
			}));
		};
		useImperativeHandle(ref, () => ({
			confirm() {
				dispatch(
					SetLocationAction({
						city: viewState.city,
						country: viewState.countryCode,
						country_code: viewState.countryCode,
						latitude: viewState.latitude,
						longitude: viewState.longitude,
						postal: viewState.postCode,
						region_code: ""
					})
				);
			}
		}));

		return (
			<div className="h-full relative map-container">
				<div
					ref={mapContainer}
					className="h-full [&_.mapboxgl-ctrl] overflow-hidden"
				>
					<Map
						initialViewState={viewState}
						mapStyle="mapbox://styles/mapbox/streets-v9"
						mapboxAccessToken={TOKEN}
						ref={map}
						onZoom={(event) => setZoom(event.viewState.zoom)}
					>
						<GeocoderControl
							mapboxAccessToken={TOKEN}
							position="top-left"
							onResult={handleChangeLocation}
							placeholder="ZIP/Postal Code, City or State/Province"
							render={(item) => {
								const placeName = item.place_name.split(",");
								return (
									'<div class="h-11 m-0 rounded-lg flex flex-row justify-between items-center p-3.5 hover:bg-[#EFF1FD]"><div class="text-base font-medium text-[#644C70]">' +
									placeName.join(",") +
									"</div>" +
									'<button onclick="handleChangeLocation(e)" class="border w-[50px] h-[25px] text-white font-semibold bg-[#5E78FF] border-[#4B76C2] rounded text-xs">Select</button>' +
									"</div>"
								);
							}}
						/>
						<Source
							id="circle-data"
							type="geojson"
							data={{
								features: [
									{
										geometry: {
											coordinates: [
												viewState.longitude,
												viewState.latitude
											],
											type: "Point"
										},
										properties: {},
										type: "Feature"
									}
								],
								type: "FeatureCollection"
							}}
						>
							<Layer
								id="circle"
								type="circle"
								paint={{
									"circle-color": "rgba(94, 120, 255, 0.35)",
									"circle-radius": radius,
									"circle-stroke-color": "#4357BE",
									"circle-stroke-width": 1
								}}
							/>
						</Source>
					</Map>
				</div>
				<div className="absolute bottom-5 right-5 rounded-t-full rounded-b-full overflow-hidden">
					<button
						className="w-8 h-8 flex items-center justify-center bg-[#FF7350] cursor-pointer"
						onClick={() =>
							setZoom((value) => {
								const newVal = value + 1;
								map.current!.setZoom(newVal);
								return newVal;
							})
						}
					>
						<ImPlus className="w-4 h-4 stroke-white stroke-1 text-transparent" />
					</button>
					<span className="flex h-[1px]" />
					<button
						className="w-8 h-8 flex items-center justify-center bg-[#FF7350] cursor-pointer"
						onClick={() =>
							setZoom((value) => {
								const newVal = value - 1;
								map.current!.setZoom(newVal);
								return newVal;
							})
						}
					>
						<ImMinus className="w-4 h-4 stroke-white stroke-1 text-transparent" />
					</button>
				</div>
			</div>
		);
	}
);

export default MapContainer;
