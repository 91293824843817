import { Link } from "react-router-dom";
import Logo from "../assets/autoyaya-logo.svg";
import Linkedin from "../assets/linkedIn.svg";
import Instagram from "../assets/instagram.svg";

const Footer = () => {
	return (
		<footer className="flex-0 footer h-30">
			<div className="bg-[#3B2F66] py-[50px] px-[20px] md:px-[60px]">
				<div className="flex justify-between">
					<Link to="/" className="logo">
						<img src={Logo} alt="Logo" />
					</Link>
					<div className="flex">
						<Link to="#">
							<img src={Linkedin} />
						</Link>
						<Link to="#">
							<img src={Instagram} className="ml-[9px]" />
						</Link>
					</div>
				</div>
				<hr className="my-[45px] border-[#D9D9D9]" />
				<div className="grid grid-cols-3 gap-[10px]">
					<div className="flex flex-col gap-[16px] ">
						<span className="font-[700] text-[#C8D1FF]">About</span>
						<a
							className="break-words underline text-[#FFF] decoration-[#FFF] underline-offset-4 cursor-pointer"
							href="https://corp.carbeeza.com/"
							target="_blank"
						>
							Carbeeza
						</a>
						<a
							className="break-words underline text-[#FFF] decoration-[#FFF] underline-offset-4 cursor-pointer"
							href="https://www.ultralead.ai/"
							target="_blank"
						>
							Ultralead
						</a>
						{/* <a
							className="break-words underline text-[#FFF] decoration-[#FFF] underline-offset-4 cursor-pointer"
							href="#"
							target="_blank"
						>
							About Autoyaya
						</a> */}
						<a
							className="break-words underline text-[#FFF] decoration-[#FFF] underline-offset-4 cursor-pointer"
							href="https://corp.carbeeza.com/investor-relations"
							target="_blank"
						>
							Investor Relations
						</a>
					</div>
					<div className="flex flex-col gap-[16px] ">
						<span className="font-[700] text-[#C8D1FF]">
							Resources
						</span>
						<a
							className="break-words underline text-[#FFF] decoration-[#FFF] underline-offset-4 cursor-pointer"
							href="https://corp.carbeeza.com/news-and-articles"
							target="_blank"
						>
							News
						</a>
					</div>
					<div className="flex flex-col gap-[16px] ">
						<span className="font-[700] text-[#C8D1FF]">
							Support
						</span>
						<a
							className="break-words underline text-[#FFF] decoration-[#FFF] underline-offset-4 cursor-pointer"
							href="https://corp.carbeeza.com/contactus"
							target="_blank"
						>
							Contact Us
						</a>
					</div>
				</div>
			</div>
			<div className="bg-[#1F1A32] py-[36px] px-[20px] md:px-[55px]">
				<div className="md:hidden mb-[32px]">
					<a
						className="text-[#FFF] text-[16px] cursor-pointer"
						href="https://corp.carbeeza.com/contactus"
						target="_blank"
					>
						Copyright © Carbeeza 2024
					</a>
				</div>
				<div className="flex justify-between ">
					<div className="hidden md:block">
						<a
							className="text-[#FFF] text-[16px] cursor-pointer"
							href="https://corp.carbeeza.com/contactus"
							target="_blank"
						>
							Copyright © Carbeeza 2024
						</a>
					</div>
					<div className="flex">
						<a
							className="break-words underline text-[#FFF] decoration-[#FFF] underline-offset-4 cursor-pointer"
							href="https://corp.carbeeza.com/dealer-terms-of-service"
							target="_blank"
						>
							Dealer Terms of Service
						</a>
						<a
							className="ml-[25px] break-words underline text-[#FFF] decoration-[#FFF] underline-offset-4 cursor-pointer"
							href="https://corp.carbeeza.com/privacy-policy"
							target="_blank"
						>
							Privacy Policy
						</a>
						{/* <a
						className="ml-[25px] break-words underline text-[#FFF] decoration-[#FFF] underline-offset-4 cursor-pointer"
						href="#"
						target="_blank"
					>
						Contact Support
					</a> */}
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
