import { useState, useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { VehicleContext } from "@/common/contexts";
import type { RootState } from "@/common/models";
import { getEstimatedPayment } from "@/common/services/apiService";
import InputWithSingleValue from "@/components/InputWithSingleValue";
import type { EstimatedPaymentResults } from "@/pages/Vehicles/EstimatedPayment";
import {
	debug,
	postalFormat,
	zipFormat,
	isValidPostal,
	isValidZip
} from "@/utils/common";

import DownPayment from "./DownPayment";
import LoanTerm from "./LoanTerm";

export interface BuildPaymentParam {
	LoanTermMin?: number;
	LoanTermMax?: number;
	Downpayment?: number;
	ListPrice?: number;
	VehicleVin?: string;
	VehicleMake?: string;
	VehicleModel?: string;
	VehicleTrim?: string;
	MonthlyPayment?: number;
	Income?: number;
	PostCode?: string;
	CreditScore?: number;
	TradeinValue?: number;
	Country?: string;
}
// interface EstimatedPaymentResponse {
// 	status_code?: number;
// 	data?: EstimatedPaymentResults;
// 	message?: string;
// }

interface BuildPaymentProps {
	setEstimatedPayment: (data: EstimatedPaymentResults) => void;
	setUserBuildParam: (data: BuildPaymentParam) => void;
	product: any;
	errors: string[];
	setErrors: (data: string[]) => void;
}

const BuildPayment = ({
	product,
	setEstimatedPayment,
	setUserBuildParam,
	errors,
	setErrors
}: BuildPaymentProps) => {
	const { buildParam, setBuildParam } = useContext(VehicleContext);

	const location = useSelector((state: RootState) => state.app.location);

	const [inputAction, setInputAction] = useState(false);
	const isFirstLoad = useRef(true);
	const generatePayment = async () => {
		if (
			!isValidPostal(buildParam.PostCode) &&
			!isValidZip(buildParam.PostCode)
		) {
			return;
		}

		debug("generate payment function");
		const params = {
			Country:
				buildParam.Country ||
				product?.details?.va_seller_country ||
				"CA",
			CreditScore: buildParam.CreditScore,
			DownPayment: buildParam.Downpayment,
			Income: buildParam.Income,
			ListPrice: product?.price,
			LoanTermMax: buildParam.LoanTermMax,
			LoanTermMin: buildParam.LoanTermMin,
			MonthlyPayment: buildParam.MonthlyPayment,
			PostCode: buildParam.PostCode,
			TradeinValue: buildParam.TradeinValue,
			VehicleMake: product?.make,
			VehicleModel: product?.model,
			VehicleTrim: product?.trim,
			VehicleVin: product?.vin
		};
		setUserBuildParam(params);

		//setEstimating(true);
		getEstimatedPayment(params)
			.then((res: any) => {
				debug("response: ", res);
				//setEstimating(false);
				if (res.status === 200) {
					const resData: any = res?.data?.data;
					const resErrors = res?.data?.errors;
					const payment = {
						CostofInterest: resData?.programs[0].cost_of_interest,
						InterestRate: resData?.programs[0].rate,
						MaxDownPayment: resData?.max_downpayment,
						MinDownPayment: resData?.min_downpayment,
						MonthlyPayment: resData?.programs[0].estimated_monthly,
						Term: resData?.programs[0].term,
						TotalCostofOwnership:
							resData?.programs[0].total_cost_of_ownership
					};
					setEstimatedPayment(payment);
					setErrors(resErrors);
				}
			})
			.catch(() => {
				//setEstimating(false);
			});
	};
	useEffect(() => {
		//skip first load
		if (isFirstLoad.current) {
			setPostCode(String(location.postal));
			isFirstLoad.current = false;
			// return;
		}
		const timeoutId = setTimeout(() => {
			generatePayment();
		}, 3000);
		return () => clearTimeout(timeoutId);
	}, [inputAction]);

	// Reset timer on every change
	const generatePaymentDebounced = () => {
		setInputAction(!inputAction);
	};

	const setAnnualIncome = (value: number) => {
		const param: BuildPaymentParam = { ...buildParam, Income: value };
		setBuildParam(param);
		setUserBuildParam(param);
		debug(param);
	};

	const setPostCode = (value: string) => {
		const param: BuildPaymentParam = { ...buildParam, PostCode: value };
		setBuildParam(param);
		setUserBuildParam(param);
		debug(param);
	};

	const setCreditScore = (value: number) => {
		const param: BuildPaymentParam = { ...buildParam, CreditScore: value };
		setBuildParam(param);
		debug(param);
	};

	const setTradeinValue = (value: number) => {
		const param: BuildPaymentParam = { ...buildParam, TradeinValue: value };
		setBuildParam(param);
		setUserBuildParam(param);
		debug(param);
	};

	return (
		<div>
			<div className="flex flex-col gap-[24px]">
				<div className="grid md:grid-cols-2 gap-[58px]">
					<div>
						<p className="mb-3 text-sm font-[500] leading-[120%] tracking-[0.4px]">
							{location?.country_code === "CA" ? "Postal" : "Zip"}{" "}
							Code
						</p>
						<div className="relative">
							<input
								className={`${
									isValidPostal(buildParam.PostCode) ||
									isValidZip(buildParam.PostCode)
										? "border border-[#D9D9D9]"
										: "border border-[#FF0000] bg-[#f9aeae]"
								} text-[16px] md:text-sm font-medium w-full rounded-lg px-7 py-2`}
								value={buildParam.PostCode}
								onChange={(e) => {
									if (location?.country_code === "CA") {
										setPostCode(
											postalFormat(e.target.value)
										);
									} else {
										setPostCode(zipFormat(e.target.value));
									}
								}}
							/>
						</div>
					</div>
					<InputWithSingleValue
						defaultInputValue={buildParam.Income}
						slider={false}
						label="Annual Income"
						showCurrencySymbol={true}
						setValue={setAnnualIncome}
						onChangeAction={generatePaymentDebounced}
						showCurrencyCode={true}
						country={location?.country_code}
						showFormatted={true}
					/>
				</div>
				<div className="grid md:grid-cols-2 gap-[58px]">
					<InputWithSingleValue
						showRange={true}
						defaultInputValue={buildParam.CreditScore}
						slider={true}
						label="Credit Score"
						showCurrencySymbol={false}
						sliderRange={[300, 900]}
						gradTrack={true}
						toolTip={true}
						setValue={setCreditScore}
						onChangeAction={generatePaymentDebounced}
						labelGap={true}
					/>
					<LoanTerm onChangeAction={generatePaymentDebounced} />
				</div>

				<div className="grid md:grid-cols-2 gap-[58px]">
					<InputWithSingleValue
						showRange={true}
						defaultInputValue={buildParam.TradeinValue}
						slider={true}
						label="Estimated Trade-In Value"
						showCurrencySymbol={true}
						sliderRange={[0, 12000]}
						setValue={setTradeinValue}
						onChangeAction={generatePaymentDebounced}
					/>
					<DownPayment
						listing_price={Number(product?.price)}
						onChangeAction={generatePaymentDebounced}
						errors={errors}
					/>
				</div>
			</div>
		</div>
	);
};

export default BuildPayment;
