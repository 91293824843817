import { CONSTANTS } from "@/utils/common";

export interface GeneralState {
	loading?: boolean;
	page?: number;
	pageLimit?: number;
}

const generalInitialState = {
	loading: false,
	page: 0,
	pageLimit: CONSTANTS.itemsPerPage
};

export default generalInitialState;
