import _ from "lodash";
import { useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { connect, useDispatch, useSelector } from "react-redux";
import type { AnyAction, Dispatch } from "redux";
import { bindActionCreators } from "redux";

import type { RootState } from "@/common/models";
import {
	ClearCriteriaAction,
	SetCriteriaAction,
	SetCriteriaListAction
} from "@/common/state/criteria/criteriaActions";
import type {
	CriteriaActionProps,
	CriteriaKey
} from "@/common/state/criteria/criteriaActions";
import type { LocationState } from "@/common/state/location/locationState";
import { ListProductsAction } from "@/common/state/product/productActions";
import type { IProduct } from "@/common/state/product/productState";
import ChangeLocationForm from "@/components/ProductList/ChangeLocationModalContent";
import { formatNumber } from "@/utils/common";

import ChipBar from "../ChipBar";
import type { Option } from "../Dropdown";
import Dropdown from "../Dropdown";
import Modal from "../Modal";
import Pagination from "../Pagination";
import ToastNotification from "../ToastNotification";

// import PaginationUnlimited from '../PaginationUnlimited'

const options: any = [
	{ label: "Recommended", value: "recommended" },
	{ label: "Lowest price", value: "price_asc" },
	{ label: "Highest price", value: "price_desc" }
];

export interface IFilter {
	label?: string;
	category?: CriteriaKey;
	show?: boolean;
	bgColor?: string;
}
const ProductList = (props: {
	count?: number;
	loading?: boolean;
	location?: LocationState;
	products?: IProduct[];
	listProducts: (params: any) => Promise<IProduct[]>;
	setCriteriaList: (c: CriteriaActionProps[]) => void;
}) => {
	const [showToast, setShowToast] = useState(false);
	const criteria = useSelector((state: RootState) => state.app.criteria);
	const dispatch = useDispatch();

	const setCriteria = (key: CriteriaKey, value: any) =>
		dispatch(SetCriteriaAction({ key, value }));

	const handleClearAllFilter = () => {
		dispatch(ClearCriteriaAction());
	};

	const handleChangeOrder = (opt: Option) => {
		setCriteria("sort", opt.value);
	};

	const toggleToast = () => {
		setShowToast(true);
		setTimeout(() => {
			// close notification after 5 seconds
			setShowToast(false);
		}, 5000);
	};

	return (
		<div className="pl-5 lg:pl-0 w-full pr-5">
			{showToast && (
				<ToastNotification onClose={() => setShowToast(false)} />
			)}

			{/* Table and Desktop */}
			<div className="hidden md:flex justify-between py-5 border-b border-t w-full px-5">
				<div className="flex gap-5 flex-wrap">
					<ChipBar />
					{Object.values(criteria?.enable || []).includes(true) && (
						<button
							onClick={handleClearAllFilter}
							className="text-sm text-primary"
						>
							Clear All
						</button>
					)}
				</div>
				<div>
					<Dropdown
						className="min-w-[175px] ml-2"
						value={_.find(options, [
							"value",
							criteria?.criteria?.sort
						])}
						options={options}
						onChange={handleChangeOrder}
					/>
				</div>
			</div>
			{/* Mobile */}
			<div className="md:hidden justify-between py-5 border-b border-t w-full px-5">
				<div className="w-full mb-[20px]">
					<Dropdown
						className="w-full"
						value={_.find(options, [
							"value",
							criteria?.criteria?.sort
						])}
						options={options}
						onChange={handleChangeOrder}
					/>
				</div>
				<div className="flex w-full gap-5 flex-wrap">
					<ChipBar />
					{Object.values(criteria?.enable || []).includes(true) && (
						<button
							onClick={handleClearAllFilter}
							className="text-sm text-primary"
						>
							Clear All
						</button>
					)}
				</div>
			</div>
			<div className="flex py-5 border-b w-full px-5 justify-between">
				<p className="text-base flex items-center gap-5">
					<span>
						<FaMapMarkerAlt className="text-accent w-4 h-5" />
					</span>
					{props.location?.city}, {props.location?.region_code}
					<Modal
						title={"Change Location"}
						body={({ toggleModal }) => (
							<ChangeLocationForm toggleModal={toggleModal} />
						)}
					>
						<span className="text-[#4038FF] cursor-pointer ">
							Change Location
						</span>
					</Modal>
				</p>

				<p className="text-accent flex ml-6 text-base">
					{formatNumber(Number(props.count))} Vehicles
				</p>
			</div>
			{!props.loading && props.products && (
				// <PaginatedItems items={props.products} itemsPerPage={20} />
				<Pagination items={props.products} toggleToast={toggleToast} />
				// <PaginationUnlimited />
			)}
			{props.loading && <h3 className="text-center m-6">Loading...</h3>}
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	count: state.app.product.count,
	loading: state.view.general.loading,
	location: state.app.location,
	products: state.app.product.products
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
	listProducts: bindActionCreators(ListProductsAction, dispatch),
	setCriteriaList: bindActionCreators(SetCriteriaListAction, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
