export interface ICoordinate {
	latitude?: number;
	longitude?: number;
}
export interface LocationState {
	latitude?: number;
	longitude?: number;
	city?: string;
	country?: string;
	postal?: string;
	country_code?: string;
	region?: string;
	region_code?: string;
	ip?: string;
}

const loadFromLocalStorage: () => LocationState | undefined = () => {
	try {
		const item = sessionStorage.getItem("location");
		if (item === null) return undefined;
		return JSON.parse(item);
	} catch (error) {
		console.warn(error);
		return undefined;
	}
};

const locationEmptyState: LocationState = {
	city: "",
	country: "",
	country_code: "",
	ip: "",
	latitude: 0.0,
	longitude: 0.0,
	postal: "",
	region: "",
	region_code: ""
};

const locationInitialState: LocationState =
	loadFromLocalStorage() || locationEmptyState;

export default locationInitialState;
