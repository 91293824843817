import { useEffect, useState } from "react";

import { CONSTANTS, formatMoney } from "@/utils/common";

import ContactDealerModal from "../ContactDealerModalContent";

import BuildPayment from "./BuildPayment";
import type { BuildPaymentParam } from "./BuildPayment";
import { ERRORS } from "@/common/constants";

export interface EstimatedPaymentResults {
	TotalCostofOwnership: number;
	MonthlyPayment: number;
	Term: number;
	InterestRate: number;
	CostofInterest: number;
	MinDownPayment: number;
	MaxDownPayment: number;
}

export interface EstimatedPaymentProps {
	product: any;
}

const MAX_MILEAGE_KM = 165000;
const MAX_VEHICLE_AGE = 10;

const EstimatedPayment = ({ product }: EstimatedPaymentProps) => {
	const [showExceedsMileage, setShowExceedsMileage] = useState(false);
	const [showExceedsAge, setShowExceedsAge] = useState(false);
	const [showContactDealer, setShowContactDealer] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);
	const onClose = () => {
		setShowContactDealer(false);
	};
	const year = new Date().getFullYear();
	useEffect(() => {
		setShowExceedsMileage(
			Math.round(product.mileage * CONSTANTS.kmPerMile) > MAX_MILEAGE_KM
		);
		setShowExceedsAge(year - product.year > MAX_VEHICLE_AGE);
	}, []);
	const [estimatedPayment, setEstimatedPayment] =
		useState<EstimatedPaymentResults>({
			CostofInterest: 0,
			InterestRate: 0,
			MaxDownPayment: 0,
			MinDownPayment: 0,
			MonthlyPayment: 0,
			Term: 0,
			TotalCostofOwnership: 0
		});
	const [userBuildParam, setUserBuildParam] = useState<BuildPaymentParam>({});

	return (
		<div id="payment" className=" w-full m-0 ">
			<div
				className={`mb-8 bg-[#FFFAF8] border border-[#DBBCB1] py-[20px] px-[20px] rounded-lg flex flex-col gap-[20px]`}
			>
				{!showExceedsMileage && !showExceedsAge ? (
					<>
						<p className="flex justify-between items-center w-full">
							<span className="text-[#2D2332] font-[500] text-[20px]">
								Personal Loan Calculator
							</span>
						</p>
						<p className="pb-[20px] border-b border-[#D7D7D9]">
							<span className="text-[#89C664] font-[500] text-[32px] md:text-[58px] font-circular ">
								{formatMoney(estimatedPayment.MonthlyPayment)}{" "}
							</span>
							<span className="text-[12px] font-[600] md:absolute md:mt-[25px] md:ml-[20px] font-circular ">
								{product?.details.va_seller_country === "USA"
									? "USD"
									: "CAD"}
								/Month
							</span>
						</p>
						<div className="border-b border-[#D7D7D9] pb-[16px] mb-[20px] md:flex justify-center items-start gap-[20px] lg:gap-[39px]">
							<div>
								<p className="text-black font-circular text-xs leading-5 font-[600] text-[12px]">
									Interest Rate
								</p>
								<p className="text-[#4357BE] font-[600] text-[24px]">
									{estimatedPayment.InterestRate}
									{"% "}
									<span className="text-[12px] font-[600]">
										APR {estimatedPayment.Term} months
									</span>
								</p>
							</div>
							<div>
								<p className="text-black font-circular text-xs leading-5 font-[600] text-[12px] mt-[40px] md:mt-0">
									Total Cost of Ownership (Vehicle +
									Interest)*
								</p>
								<p className="text-[#4357BE]  font-[600] text-[24px]">
									{formatMoney(
										estimatedPayment.TotalCostofOwnership
									)}{" "}
									<span className="text-[12px] font-[600]">
										{product?.details.va_seller_country ===
										"USA"
											? " USD"
											: " CAD"}
									</span>
								</p>
							</div>
						</div>

						<BuildPayment
							product={product}
							setEstimatedPayment={setEstimatedPayment}
							setUserBuildParam={setUserBuildParam}
							errors={errors}
							setErrors={setErrors}
						/>
					</>
				) : (
					<>
						{showExceedsAge && (
							<span className="font-normal p-3">
								Vehicle cannot be financed because vehicle age
								exceeds 10 years.
							</span>
						)}
						{showExceedsMileage && (
							<span className="font-normal p-3">
								Vehicle cannot be financed because mileage
								exceeds 165,000 kilometers.
							</span>
						)}
					</>
				)}
				{errors?.includes(ERRORS.NON_FINANCIBLE) && (
					<span className="font-normal p-3">
						For the lenders available for this vehicle, down payment
						must be at least{" "}
						{formatMoney(estimatedPayment.MinDownPayment)}
					</span>
				)}
				{errors?.includes(ERRORS.AMOUNT_TOO_SMALL) && (
					<span className="font-normal p-3">
						For the lenders available for this vehicle, down payment
						must be less than{" "}
						{formatMoney(estimatedPayment.MaxDownPayment)}
					</span>
				)}

				<div>
					{!product.details.carbeeza_customer &&
						product.details.listing_vdp_url && (
							<a
								href={`${product.details.listing_vdp_url}`}
								target="_blank"
							>
								<button className="btn bg-[#5E78FF] font-[450] w-full py-4 rounded-lg text-white">
									Contact Dealer
								</button>
							</a>
						)}

					{product.details.carbeeza_customer === true && (
						<button
							onClick={() => {
								setShowContactDealer(true);
							}}
							className="text-[20px] btn bg-[#5E78FF] font-[450] w-full py-4 rounded-lg text-white"
						>
							Contact Dealer
						</button>
					)}
					{showContactDealer && (
						<ContactDealerModal
							onClose={onClose}
							product={product}
							userBuildParam={userBuildParam}
						/>
					)}

					<p className="text-[12px] font-[450] leading-normal mt-[50px]">
						<span className="font-semibold">* Disclaimer:</span>{" "}
						Additional Taxes and Fees may apply
					</p>
				</div>
			</div>
		</div>
	);
};

export default EstimatedPayment;
