import { CONSTANTS } from "@/utils/common";

import type { CriteriaKey } from "./criteriaActions";

export type TCriteria = {
	[k in CriteriaKey]?: any;
};

export interface CriteriaState {
	criteria: TCriteria;
	enable?: {
		[k in CriteriaKey]?: boolean;
	};
}

export const loadCriteriaFromLocalStorage: () =>
	| CriteriaState
	| undefined = () => {
	try {
		const item = sessionStorage.getItem("criteria");
		if (item === null) return undefined;
		return JSON.parse(item);
	} catch (error) {
		console.warn(error);
		return undefined;
	}
};

export const criteriaEmptyState: CriteriaState = {
	criteria: {
		distance: 25,
		distance_unit: "K",
		listing_mileage: [0, 1669127],
		listing_price: [CONSTANTS.minPrice, CONSTANTS.maxPrice],
		mileage_unit: "K",
		sort: "recommended",
		// sort: "price_asc",
		vehicle_drivetrains: [],
		vehicle_engine_cylinders: [],
		vehicle_fuel_types: [],
		vehicle_makes: [],
		vehicle_models: [],
		vehicle_transmission_types: [],
		vehicle_trims: [],
		vehicle_year: [1970, new Date().getFullYear() + 1]
	},
	enable: {
		distance: false,
		distance_unit: false,
		listing_mileage: false,
		listing_price: false,
		mileage_unit: false,
		sort: false,
		vehicle_drivetrains: false,
		vehicle_engine_cylinders: false,
		vehicle_fuel_types: false,
		vehicle_makes: false,
		vehicle_models: false,
		vehicle_transmission_types: false,
		vehicle_trims: false,
		vehicle_year: false
	}
};
const criteriaInitialState: CriteriaState =
	loadCriteriaFromLocalStorage() || criteriaEmptyState;

export default criteriaInitialState;
