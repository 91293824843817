import { useEffect, useState } from "react";
import NumericInput from "react-numeric-input";
import { useSelector, useDispatch } from "react-redux";
import ReactSlider from "react-slider";
import Switch from "react-switch";

import type { RootState } from "@/common/models";
import { SetCriteriaAction } from "@/common/state/criteria/criteriaActions";
import type { TCriteria } from "@/common/state/criteria/criteriaState";
import { formatNumber, parseNumber, debug } from "@/utils/common";

import type { Option } from "../Dropdown/index";
import Dropdown from "../Dropdown/index";
import { ArrowLeftIcon, ArrowRightIcon, PlusIcon } from "../Elements";

const YearAndMileage = () => {
	const dispatch = useDispatch();
	// TODO: Fix typing for Redux state/logic
	const criteria: TCriteria = useSelector(
		(state: RootState) => state.app.criteria.criteria
	);
	const listingMileage: number[] = useSelector(
		(state: RootState) => state.app.criteria.criteria.listing_mileage
	) || [0, 1669127];
	const mileageUnit: string =
		useSelector(
			(state: RootState) => state.app.criteria.criteria.mileage_unit
		) || "N";
	const [yearOptions, setYearOptions] = useState<Option[]>([]);

	const [show, setShow] = useState(true);

	useEffect(() => {
		const optionsArray = [];
		for (
			let index = 0;
			index <= new Date().getFullYear() + 1 - 1970;
			index++
		) {
			const constructOption: Option = {
				label: String(1970 + index),
				value: parseInt(String(1970 + index), 10)
			};
			optionsArray.push(constructOption);
		}
		setYearOptions(optionsArray);
	}, []);

	const setYear = (year: number[]) => {
		if (year[0] > year[1]) {
			year[0] = year[1];
		}

		dispatch(
			SetCriteriaAction({
				key: "vehicle_year",
				value: year
			})
		);
	};
	const setMileageUnit = (value: boolean) => {
		dispatch(
			SetCriteriaAction({
				key: "mileage_unit",
				value: value ? "K" : "N"
			})
		);
	};
	const setListingMileage = (mileage: number[]) => {
		if (mileage[0] > mileage[1]) {
			mileage[0] = mileage[1];
		}
		dispatch(
			SetCriteriaAction({
				key: "listing_mileage",
				value: mileage
			})
		);
	};
	debug("mileage", criteria.listing_mileage);
	return (
		<div className="">
			<div
				className="text-base text-accent font-medium relative cursor-pointer pb-3 border-b-2 border-[#C8D1FF] mb-6"
				onClick={() => setShow((value) => !value)}
			>
				Year & Mileage{" "}
				<span
					className={`${
						show ? "active" : ""
					} absolute right-0 top-[5px] text-[#64748B] dropdown-button`}
				>
					<PlusIcon />
				</span>
			</div>
			<div
				className={`${
					show ? "!overflow-visible" : "closed"
				} mb-5 dropdown-container`}
			>
				<div className="mb-6">
					<p className="mb-3 text-[12px]">Year</p>
					<div className="flex justify-between text-[12px] text-[#0F172A]">
						<p>From</p>
						<p className="mr-6">To</p>
					</div>
					<div className="mb-2">
						<ReactSlider
							value={[
								criteria.vehicle_year[0] || 0,
								criteria.vehicle_year[1] || 0
							]}
							max={new Date().getFullYear() + 1}
							min={1970}
							onAfterChange={(value) => {
								setYear(value);
							}}
							className="slider-year"
							thumbClassName="slider-year-thumb"
							trackClassName="slider-year-track"
							renderThumb={(props) => {
								return (
									<div
										{...props}
										className={`${props.className} flex justify-center items-center`}
									>
										<span className="flex items-center relative h-full w-full">
											<ArrowLeftIcon />
											<ArrowRightIcon />
										</span>
									</div>
								);
							}}
						/>
					</div>
					<div className="flex justify-between text-[12px] text-[#94A3B8] font-bold mb-2">
						<p>Year from</p>
						<p>Year to</p>
					</div>
					<div className="flex justify-between gap-4">
						<Dropdown
							value={{
								label: String(criteria.vehicle_year[0]),
								value: criteria.vehicle_year[0] || 0
							}}
							options={yearOptions}
							onChange={(value) => {
								setYear([
									Number(value.label),
									criteria.vehicle_year[1] || 0
								]);
							}}
						/>
						<Dropdown
							value={{
								label: String(criteria.vehicle_year[1]),
								value: criteria.vehicle_year[1] || 0
							}}
							options={yearOptions}
							onChange={(value) => {
								setYear([
									criteria.vehicle_year[0] || 0,
									Number(value.label)
								]);
							}}
						/>
					</div>
				</div>

				<div>
					<p className="mb-3">Mileage</p>
					<div className="mb-4">
						<label
							htmlFor="material-switch"
							className="flex items-center gap-3"
						>
							<span className="text-[12px]">Miles</span>
							<Switch
								checked={mileageUnit === "K"}
								onChange={setMileageUnit}
								onColor="#EEF2F6"
								offColor="#5E78FF"
								onHandleColor="#5E78FF"
								offHandleColor="#5E78FF"
								uncheckedIcon={false}
								checkedIcon={false}
								handleDiameter={21}
								height={20}
								width={44}
								className="switch-miles"
								id="material-switch"
							/>
							<span className="text-[12px]">Kilometers</span>
						</label>
					</div>
					<div className="flex justify-between text-[12px] text-[#0F172A]">
						<p>From</p>
						<p className="mr-6">To</p>
					</div>
					<div className="mb-2">
						<ReactSlider
							value={listingMileage}
							max={1669127}
							min={0}
							onAfterChange={(value: number[]) => {
								setListingMileage(value);
							}}
							className="slider-year"
							thumbClassName="slider-year-thumb"
							trackClassName="slider-year-track"
							renderThumb={(props: any) => {
								return (
									<div
										{...props}
										className={`${props.className} flex justify-center items-center`}
									>
										<span className="flex items-center relative h-full w-full">
											<ArrowLeftIcon />
											<ArrowRightIcon />
										</span>
									</div>
								);
							}}
						/>
					</div>
					<div className="flex justify-between text-[12px] text-[#0F172A] font-bold mb-2">
						<p>0</p>
						{/* <p>400,000</p> */}
						<p>{formatNumber(1669127)}</p>
					</div>
					<div className="flex justify-between gap-4">
						<div className="max-w-[145px]">
							<NumericInput
								value={criteria.listing_mileage[0]}
								className="text-base font-medium border w-full rounded-lg border-[#D9D9D9] px-2 py-[5px]"
								// format={formatNumber}
								parse={parseNumber}
								onChange={(value) => {
									setListingMileage([
										value || 0,
										listingMileage[1]
									]);
								}}
							/>
						</div>
						<div className="max-w-[145px]">
							<NumericInput
								value={criteria.listing_mileage[1]}
								className="text-base font-medium border w-full rounded-lg border-[#D9D9D9] px-2 py-[5px]"
								// format={formatNumber}
								parse={parseNumber}
								onChange={(value) => {
									setListingMileage([
										listingMileage[0],
										value || 0
									]);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default YearAndMileage;
