export const ArrowRightIcon = () => (
	<svg
		className="absolute"
		style={{
			right: "-.5px",
			top: "50%",
			transform: "translateY(-50%) rotate(180deg)"
		}}
		xmlns="http://www.w3.org/2000/svg"
		width={12}
		height={12}
		viewBox="0 0 9 8"
		fill="none"
	>
		<path
			d="M3.2594 3.49676L3.97491 2.8401L5.14079 1.7701C5.38777 1.54676 5.80908 1.70676 5.80908 2.02676V4.10343V5.97343C5.80908 6.29343 5.38777 6.45343 5.14079 6.22676L3.2594 4.5001C2.95795 4.22676 2.95795 3.77343 3.2594 3.49676Z"
			fill="white"
		/>
	</svg>
);
