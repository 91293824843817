import { useContext } from "react";

import { ERRORS } from "@/common/constants";
import { VehicleContext } from "@/common/contexts";
import InputWithSingleValue from "@/components/InputWithSingleValue";
interface DownPaymentProps {
	listing_price: number;
	onChangeAction: any;
	errors: string[];
}

const DownPayment = ({
	listing_price,
	onChangeAction,
	errors
}: DownPaymentProps) => {
	const { buildParam, setBuildParam } = useContext(VehicleContext);
	const setDownpayment = (value: number) => {
		const param = { ...buildParam, Downpayment: value };
		setBuildParam(param);
	};
	return (
		<div>
			<p className="mb-[5px] text-[12px] font-[500] leading-[120%] tracking-[0.4px]">
				Down Payment
			</p>
			<div>
				<InputWithSingleValue
					defaultInputValue={buildParam.Downpayment}
					sliderRange={[0, listing_price]}
					slider={true}
					label=""
					showRange={true}
					showCurrencySymbol={true}
					setValue={setDownpayment}
					onChangeAction={onChangeAction}
					isError={
						errors?.includes(ERRORS.NON_FINANCIBLE) ||
						errors?.includes(ERRORS.AMOUNT_TOO_SMALL)
					}
				/>
			</div>
		</div>
	);
};

export default DownPayment;
