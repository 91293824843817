export const PlusIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={11}
		height={12}
		viewBox="0 0 11 12"
		fill="none"
	>
		<path
			d="M1.51465 6.00024L5.75729 6.00024L5.75729 1.7576"
			stroke="#64748B"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10 6.00024L5.75736 6.00024L5.75736 10.2429"
			stroke="#64748B"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
